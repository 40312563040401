import { shuffle } from '../../../helpers/Arrays';
import { BrandAwarenessBlock } from '../../../types/learning_provider';


export default function(brand_awareness_blocks: BrandAwarenessBlock[]): BrandAwarenessBlock|null {
    if (brand_awareness_blocks.length > 0) {
        // Shuffle the blocks
        const brandAwarenessBlocks = shuffle([...brand_awareness_blocks]);
        return brandAwarenessBlocks[0];
    }

    return null;
}
