import styled from '@emotion/styled';
import {
    MobileStepper,
    IconButton,
    Badge,
    Skeleton
} from '@mui/material';

export const TabsContainer = styled.div`
    margin: ${(props) => props.theme.spacing(4, 1, 0, 1)};
`;

export const NotificationBadge = styled(Badge)`
    .MuiBadge-dot {
        top: 4px;
        right: 4px;
    }
`;

export const DiscussionsWidgetTabContent = styled.div<{ selected: boolean }>`
    display: ${(props) => props.selected ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
`;

export const RefreshButtonContainer = styled.div`
    margin-top: ${(props) => props.theme.spacing(-4)};
    margin-bottom: ${(props) => props.theme.spacing(2)};
`;

export const DiscussionsWidgetContainer = styled.div`
    height: max-content;
    border: 1px solid ${(props) => props.theme.grey.main};
    border-radius: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.background.paper};
    box-sizing: border-box;
`;

export const DiscussionsWidgetTitle = styled.h3`
    margin: ${(props) => props.theme.spacing(3, 0, 0, 4)};
    color: ${(props) => props.theme.palette.primary.main};
    ${(props) => props.theme.typography.h2};
    font-weight: 700;
    height: 30px;
`;

export const DiscussionsWidgetContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props) => props.theme.spacing(4, 1, 2)};
`;

export const DiscussionsListContainer = styled.div<{ variant: 'card' | 'link' }>`
    display: flex;
    flex-wrap: wrap;
    margin: ${(props) => props.variant === 'card' ? 0 : props.theme.spacing(0, 3)};
    gap: ${(props) => props.theme.spacing(2)};
    justify-content: center;
`;

export const DiscussionsListItem = styled.div<{ numberOfItems: number }>`
    width: 100%;
    padding: ${(props) => props.theme.spacing(2, 0)};
    border-bottom: 1px solid ${(props) => props.theme.grey.main};
    ${(props) => props.theme.typography.subtitle2};
    color: ${(props) => props.theme.text.primary};
    padding-top: 0px;

    &:nth-of-type(${(props) => props.numberOfItems}) {
        padding-bottom: 0px;
        border-bottom: transparent;
    }

    @media (min-width: 671px) and (max-width: 980px) {
        width: 48%;

        // add margin to items in first column to create space between columns
        &:nth-of-type(odd) {
            margin-right: 1%;
        }

        &:nth-of-type(${(props) => props.numberOfItems - 1}) {
            padding-bottom: 0px;
            border-bottom: transparent;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const DiscussionLinkSkeletonContainer = styled(DiscussionsListItem)`
    display: flex;
    justify-content: center;
    border-bottom: none;
`;

export const DiscussionLinkSkeleton = styled(Skeleton)`
    margin: ${(props) => props.theme.spacing(0, 1)};
`;

export const StepperButton = styled(IconButton)`
    padding: ${(props) => props.theme.spacing(2)};

    &:hover {
        background-color: ${(props) => props.theme.grey.main};
    }

    &.Mui-disabled {
        visibility: hidden;
    }
`;

export const StyledStepper = styled(MobileStepper)`
    padding: 0px;
    background-color: ${(props) => props.theme.white};
    margin-top: ${(props) => props.theme.spacing(2)};
    justify-content: center;

    .MuiMobileStepper-dot {
        margin: 0px ${(props) => props.theme.spacing(2)};
        height: 7px;
        width: 7px;
        background-color: ${(props) => props.theme.grey.main};
    }

    .MuiMobileStepper-dotActive {
        background-color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const NoThreadsMessage = styled.p`
    align-self: center;
`;
