import { setUserData } from '@thestudentroom/datalayer';
import { GrowthBook } from '@growthbook/growthbook-react';

export default (gb: GrowthBook | undefined) => {
    setUserData({
        dimension1: 'home'
    });

    if (typeof window !== 'undefined') {
        if (typeof gb !== 'undefined') {
            gb.setAttributes({
                ...gb.getAttributes(),
                pageType: 'home'
            });
        }

        // Expose variables globally
        window.THIS_SCRIPT = 'home';
    }
};
