import { useSelector } from 'react-redux';
import { RootState } from '../../../client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

/* APIs */
import { DiscussionWidgetThread } from '../../../types/thread';

/* Helpers */
import { timestampCalculation } from '../../../helpers/timestampCalculation';

/* Styles */
import {
    DiscussionCardContainer,
    ThreadOverview,
    ThreadTitle,
    ThreadTagContainer,
    ThreadTags,
    ThreadDetails,
    ThreadDetailsContainer,
    ThreadTimestamp,
    UserContainer,
    ActionButtonContainer,
    ThreadPostContainer,
    ThreadStats,
    ThreadStatsLabel,
    Mobile,
    Desktop
} from './styles';
import { MdStar } from 'react-icons/md';

/* Components */
import { UserInfo } from '@thestudentroom/lib.discussions';

/* Icons */
import { DiscussionBubble, useAppTheme } from '@thestudentroom/lib.themes';
import React from 'react';
import { recordClick } from '@thestudentroom/datalayer';

interface DiscussionCardProps {
    thread: DiscussionWidgetThread;
    index: number;
    actionButton?: React.ReactNode;
    type?: string;
    category?: string;
}

export default function DiscussionCard(props: DiscussionCardProps) {
    const theme = useAppTheme();
    const userId = useSelector((state: RootState) => state.user.userId);
    const showFeedDebug: boolean = useFeatureIsOn("feed-debug") ?? false;

    if (!props.thread) return null;
    const forums = props.thread?.forums ?? [];

    let tag: any = null;
    if (forums.length > 0) {
        tag = forums[forums.length - 1];
    }

    const handleThreadClick = () => {
        const tracking = {
            group: "navigation",
            item: props.thread.title,
            position: props.index + 1,
            replyCount: props.thread.n_posts,
            section: props.type?.includes('watched') ? 'watched' : props.type,
            subsection: props.type == 'feed' ? (props.category == 'thread' ? 'for you' : props.category) : props.type,
            threadId: props.thread.id,
            type: "thread",
            forumId: forums[forums.length - 1]?.id ?? 0
        };

        recordClick(tracking);
    };

    const handleTagClick = (tagId: number, tagTitle: string) => {
        const tracking = {
            group: "navigation",
            item: tagTitle,
            position: props.index + 1,
            replyCount: props.thread.n_posts,
            section: props.type?.includes('watched') ? 'watched' : props.type,
            subsection: props.type == 'feed' ? (props.category == 'thread' ? 'for you' : props.category) : props.type,
            threadId: props.thread.id,
            type: "thread",
            forumId: tagId
        };

        recordClick(tracking);
    };

    return (
        <DiscussionCardContainer>
            <UserContainer>
                <UserInfo
                    user={props.thread.first_post.user}
                    displayOPIndicator={false}
                    isFirstPost={false}
                    hideUsergroups={true}
                    currentUserId={userId}
                />
            </UserContainer>

            <ThreadOverview>
                <ThreadTitle
                    url={`/showthread.php?t=${props.thread.id}${userId > 0 ? '&goto=newpost' : ''}`}
                    children={props.thread.title}
                    onClick={handleThreadClick}
                />

                <ThreadDetailsContainer>
                    {tag ? (
                        <ThreadTagContainer>
                            <ThreadTags
                                url={`/forumdisplay.php?f=${tag.id}`}
                                children={tag.title}
                                onClick={() => handleTagClick(tag.id, tag.title)}
                            />
                        </ThreadTagContainer>
                    ) : null}

                    {props.actionButton && (
                        <Mobile>
                            <ActionButtonContainer>{props.actionButton}</ActionButtonContainer>
                        </Mobile>
                    )}
                </ThreadDetailsContainer>
            </ThreadOverview>

            {props.actionButton && (
                <Desktop>
                    <ActionButtonContainer>{props.actionButton}</ActionButtonContainer>
                </Desktop>
            )}

            <ThreadDetails>
                {showFeedDebug && props.thread.source && <ThreadTimestamp>[{props.thread.source}]</ThreadTimestamp>}
                <ThreadTimestamp>
                    {`${timestampCalculation(props.thread.last_post.created_at)}`}
                </ThreadTimestamp>
                <ThreadPostContainer>
                    <ThreadStats>
                        <MdStar
                            size={16}
                            style={{
                                color: theme.palette.primary.main,
                                marginRight: '-2px'
                            }}
                        />
                        
                        <ThreadStatsLabel>{props.thread.subscribers ? props.thread.subscribers : 0}</ThreadStatsLabel>
                    </ThreadStats>
                    <ThreadStats>
                        <DiscussionBubble
                                width={16}
                                height={13}
                                bottomRight={true}
                                bgColor={theme.palette.primary.main}
                                style={{
                                    padding: 0
                                }}
                            />

                        <ThreadStatsLabel>{props.thread.n_posts}</ThreadStatsLabel>
                    </ThreadStats>
                </ThreadPostContainer>
            </ThreadDetails>
        </DiscussionCardContainer>
    );
}
