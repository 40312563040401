import styled from '@emotion/styled';
import Card from '@mui/material/Card';

export const MemberBannerCard = styled(Card)`
    display: flex;
    flex-direction: column;
    border-radius: ${(props) => props.theme.spacing(5)};
    background-color: ${(props) => props.theme.lilac.light};
    margin-top: ${(props) => props.theme.spacing(10)};

    @media (min-width: 671px) {
        flex-direction: row;
    }
`;

export const MemberCardImageContainer = styled.div`
    background-color: ${(props) => props.theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: ${(props) => props.theme.spacing(4)};

    @media (min-width: 671px) {
        width: 360px;
    }
`;

export const MemberCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(4)};
    justify-content: space-between;
    padding: ${(props) => props.theme.spacing(4, 8, 8, 8)};

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(4, 8)};
    }
`;

export const MemberCardTitle = styled.div`
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;

    @media (min-width: 820px) {
        font-size: 26px;
        line-height: 38px;
    }
`;

export const MemberCardText = styled.div`
    ${(props) => props.theme.typography.body1};
    line-height: 28px;
`;
