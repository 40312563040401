export default () => {
    let retries = 0;
    const scrollToPost = setInterval(() => {
        retries++;
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                const scrollToY = anchorEl.getBoundingClientRect().top + window.scrollY - 300;
                window.scrollTo({ top: scrollToY, behavior: 'smooth' });
                clearInterval(scrollToPost);
            }
        }

        if (retries > 20) {
            clearInterval(scrollToPost);
        }
    }, 500);
};
