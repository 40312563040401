import styled from '@emotion/styled';
import Link from '../../Link';

export const DiscussionCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    background-color: ${(props) => props.theme.lilac.light};
    border-radius: ${(props) => props.theme.spacing(3)};
    box-sizing: border-box;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding: ${(props) => props.theme.spacing(2)};

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }
`;

export const UserContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 60%;
    order: 1;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 215px;
    }
`;

export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    min-width: 116px;

    @media (min-width: 768px) {
        order: 4;
    }
`;

export const ThreadOverview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    order: 3;
    width: 100%;
    margin-top: ${(props) => props.theme.spacing(2)};
    padding: ${(props) => props.theme.spacing(0, 2)};

    @media (min-width: 768px) {
        order: 2;
        flex-grow: 1;
        width: auto;
        margin-top: ${(props) => props.theme.spacing(0)};
        padding: ${(props) => props.theme.spacing(0)};
        width: 45%;
    }
`;

export const ThreadTitle = styled(Link)`
    color: ${(props) => props.theme.palette.success.contrastText};
    ${(props) => props.theme.typography.subtitle1};
    letter-spacing: 0.32px;
    text-wrap: wrap;
`;

export const ThreadDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props) => props.theme.spacing(2)};
    width: 100%;
    justify-content: space-between;

    @media (min-width: 768px) {
        width: auto;
    }
`;

export const ThreadTagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props) => props.theme.spacing(2)};
    align-items: center;
`;

export const ThreadTags = styled(Link)`
    color: ${(props) => props.theme.palette.primary.main};
    ${(props) => props.theme.typography.subtitle1};
    letter-spacing: 0.32px;
`;

export const ThreadDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: ${(props) => props.theme.spacing(4)};
    width: 40%;
    order: 2;

    @media (min-width: 768px) {
        order: 5;
        width: 150px;
    }
`;

export const ThreadTimestamp = styled.p`
    color: ${(props) => props.theme.grey.mid};
    text-align: right;
    ${(props) => props.theme.typography.subtitle1};
    font-size: 13px;
    letter-spacing: 0.26px;
    margin: ${(props) => props.theme.spacing(0)};
`;

export const ThreadStatsLabel = styled.p`
    color: ${(props) => props.theme.palette.success.contrastText};
    text-align: right;
    ${(props) => props.theme.typography.subtitle2};
    line-height: 24px;
    letter-spacing: 0.28px;
    margin: ${(props) => props.theme.spacing(0, 0, 0, 1)};
`;

export const ThreadPostContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing(3)};
    justify-content: flex-end;
`;

export const ThreadStats = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const Desktop = styled.div`
    display: block;
    order: 3;

    @media (max-width: 670px) {
        display: none;
    }
`;

export const Mobile = styled.div`
    display: block;

    @media (min-width: 671px) {
        display: none;
    }
`;
