import { useSelector, useDispatch } from 'react-redux';
import { ListItem, ListItemText } from '@mui/material';
import { RootState } from '../../../client';
import { showModal } from '../../../store/modals/actions';
import {
    JoinBannerContainer,
    JoinBannerTextContainer,
    JoinBannerTitle,
    JoinBannerText,
    JoinBannerImageContainer,
    JoinBannerList,
    JoinBannerButton
} from './styles';
import useTranslation from '../../../hooks/useTranslation';
import { runtimeConfig } from '../../../runtimeConfig';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface BannerProps {
    secondaryText: boolean;
    bulletPoints: Array<string>;
}

export default function JoinTSRBanner(props: BannerProps) {
    const modal = useSelector((state: RootState) => state.modals);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useAppTheme();

    function regPromptPressed() {
        if (!modal.showModal) {
            dispatch(showModal(true, 'home_signup', null, true));
        }
    }

    return (
        <JoinBannerContainer>
            <JoinBannerImageContainer>
                <img
                    src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/computer-multiple-browsers.svg'}
                    width={348} height={293}
                />
            </JoinBannerImageContainer>
            <JoinBannerTextContainer>
                <div data-testid="banner-title">
                    <JoinBannerTitle>
                        {t('home.Banners.Signup.heading')}
                    </JoinBannerTitle>
                </div>
                <JoinBannerText data-testid="banner-main-text">
                    {t('home.Banners.Signup.mainText')}
                </JoinBannerText>

                {props.secondaryText ? (
                    <>
                        <br />
                        <JoinBannerText data-testid="banner-secondary-text">
                            {t('home.Banners.Signup.secondaryText')}
                        </JoinBannerText>
                    </>
                ) : null}

                <JoinBannerList sx={{ listStyleType: 'disc' }} data-testid={`bulletPoints`}>
                    {props?.bulletPoints?.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{ display: 'list-item' }}
                            style={{
                                marginLeft: theme.spacing(-5),
                                paddingBottom: '0px',
                                fontSize: '16px'
                            }}
                        >
                            <ListItemText primary={t(item)} />
                        </ListItem>
                    ))}
                </JoinBannerList>

                <JoinBannerButton
                    onClick={regPromptPressed}
                >
                    {t('buttons.signup')}
                </JoinBannerButton>
            </JoinBannerTextContainer>
        </JoinBannerContainer>
    );
}
