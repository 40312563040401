import styled from '@emotion/styled';
import Link from '../../../Link';

export const DiscussionCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;    
    background-color: ${(props) => props.theme.lilac.light};
    border-radius: ${(props) => props.theme.spacing(3)};
    border-bottom-right-radius: 0;
    box-sizing: border-box;
    width: 100%;
    padding: ${(props) => props.theme.spacing(2, 3)};

    @media (min-width: 671px) and (max-width: 980px) {
        width: 49%;
    }
`;

export const LastPostTimestamp = styled.p`
    color: ${(props) => props.theme.grey.mid};
    ${(props) => props.theme.typography.caption};
    letter-spacing: 0.32px;
    margin: ${(props) => props.theme.spacing(0)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    font-weight: bold;
`;

export const ThreadLink = styled(Link)`
    color: ${(props) => props.theme.palette.success.contrastText};
    ${(props) => props.theme.typography.subtitle2};
    letter-spacing: 0.32px;
    width: 100%;
    height: 100%;
`;

export const ThreadDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing(2)};
    width: 100%;
`;

export const ThreadTag = styled(Link)`
    margin-top: ${(props) => props.theme.spacing(2)};
    color: ${(props) => props.theme.palette.primary.main};
    ${(props) => props.theme.typography.caption};
    letter-spacing: 0.32px;
    font-weight: bold;
`;

export const ThreadStatsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing(3)};
    align-items: end;
`;

export const ThreadStats = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const ThreadStatsLabel = styled.p`
    color: ${(props) => props.theme.palette.success.contrastText};
    text-align: right;
    ${(props) => props.theme.typography.subtitle2};
    letter-spacing: 0.28px;
    margin: ${(props) => props.theme.spacing(0, 0, 0, 1)};
    white-space: nowrap;
`;
