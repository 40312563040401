import { runtimeConfig } from '../../../runtimeConfig';
import { PER_PAGE } from '../../../api/Thread/graph';

export default function (thread: any, page: number) {
    const paginationUrlPrefix = '/showthread.php?t=' + thread.id;
    const totalPages = Math.ceil(thread.n_posts / PER_PAGE);
    const baseCanonicalUrl = runtimeConfig.appDomain + paginationUrlPrefix;
    return {
        pageTitle: `${thread.title} - ` + (page > 1 ? `Page ${page} - ` : '') + 'The Student Room',
        baseCanonicalUrl: baseCanonicalUrl,
        canonicalUrl: baseCanonicalUrl + (page > 1 ? `&page=${page}` : ''),
        prevCanonicalUrl: baseCanonicalUrl + (page > 2 ? `&page=${page - 1}` : ''),
        nextCanonicalUrl:
            baseCanonicalUrl +
            (page < totalPages ? `&page=${page + 1}` : page > 1 ? `&page=${page}` : ''),
        robotsState: thread.no_indexed ? 'noindex,follow' : 'all'
    };
}
