import {
    BannerTitle,
    BannerText,
    BannerContainer,
    StudentWithPhone,
    BannerTextContainer,
    BannerImageContainer,
    LeftBubble,
    BottomRightBubble,
    TopRightBubble,
    MiddleBubble
} from './styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

import { runtimeConfig } from '../../../runtimeConfig';

interface BannerProps {
    title: string;
    mainText: string;
    subText: string;
}

export default function TopBanner(props: BannerProps) {
    const theme = useAppTheme();
    return (
        <>
            <LeftBubble
                height={248}
                width={180}
                bgColor={theme.blue.richBlue}
                topLeft={true}
                bottomLeft={true}
                bgTextured={"striped"}
                radius={20}
            />

            <TopRightBubble
                height={72}
                width={220}
                bgColor={theme.blue.richBlue}
                topLeft={true}
                topRight={true}
                bottomLeft={true}
                radius={5}
            />

            <BottomRightBubble
                height={153}
                width={365}
                bgColor={theme.palette.info.main}
                bottomRight={true}
                bottomLeft={true}
                radius={10}
            />

            <BannerContainer>
                <BannerTextContainer>
                    <BannerTitle> {props.title}</BannerTitle>
                    <div>
                        <BannerText>
                            {props.mainText}
                        </BannerText>
                        <BannerText
                            subText={true}
                        >
                            {props.subText}
                        </BannerText>
                    </div>
                </BannerTextContainer>
                <BannerImageContainer>
                    <MiddleBubble
                        height={120}
                        width={170}
                        bgColor={theme.palette.background.paper}
                        radius={10}
                    />
                    <StudentWithPhone
                        src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/student-with-laptop.svg'}
                        data-testid="banner-laptop"
                    />
                </BannerImageContainer>
            </BannerContainer>

        </>
    );
}
