import { setUserData, setPageData } from '@thestudentroom/datalayer';
import { GrowthBook } from '@growthbook/growthbook-react';
import { Thread } from '../../../types/thread';

export default (gb: GrowthBook | undefined, thread: Thread, pageNumber: number) => {
    if (thread && thread.status_code == 200) {
        const forumId = thread.forums[thread.forums.length - 1].id;
        const forumCategoryId = thread.forums[0].id;
        let topics: string[] = thread.topics ?? [];
        let pageTopics: string[] = thread.page_topic_codes ?? [];
        let mainPageTopics: string[] = thread.main_page_topic_codes ?? [];

        setUserData({
            dimension1: 'threadpage',
            dimension3: forumId.toString(),
            dimension4: forumCategoryId.toString(),
            dimension10: ((thread.n_posts ?? 1) - 1).toString()
        });

        setPageData({
            pageNumber: pageNumber,
            threadId: thread.id,
            forumId: forumId,
            forumCategoryId: forumCategoryId,
            replyCount: (thread.n_posts ?? 1) - 1,
            topics: topics.join(','),
            page_topic_codes: pageTopics.join(','),
            main_page_topic_codes: mainPageTopics.join(',')
        });

        if (typeof window !== 'undefined') {
            if (typeof gb !== 'undefined') {
                gb.setAttributes({
                    ...gb.getAttributes(),
                    pageType: 'thread',
                    threadId: thread.id,
                    forumId: forumId,
                    forumCategoryId: forumCategoryId,
                    pageNumber: pageNumber,
                    replyCount: (thread.n_posts ?? 1) - 1
                });
            }

            // Expose variables globally
            window.THREAD_ID = thread.id;
            window.FORUM_ID = forumId;
            window.THIS_SCRIPT = 'threadpage';
            window.REPLYCOUNT = (thread.n_posts ?? 1) - 1;
        }
    }
};
