import { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import useTranslation from '../../hooks/useTranslation';
import { RootState } from '../../client';

/* Styles */
import {
    LayoutInner,
    LayoutContentInner,
    MainContent,
    Sidebar
} from '../../Layouts/Main/styles';
import {
    PromoBlockInnerContainer,
    MainBannerContainer,
    SeasonalContainer,
    JoinContainer,
    AppReminderContainer,
    QuoteBannerContainer
} from './styles';

/* Helpers */
import setAdvertTargeting from './Helpers/setAdvertTargeting';
import setPageTracking from './Helpers/setPageTracking';
import { useGrowthBook, useFeatureIsOn } from '@growthbook/growthbook-react';
import { runtimeConfig } from '../../runtimeConfig';
import showExistingMemberDPC from '../../helpers/DataCapture/showExistingMemberDPC';
import { showModal } from '../../store/modals/actions';

/* Components */
import SidebarHPUWrapper from '../../components/Ads/SidebarHPU/SidebarHPUWrapper';
import SidebarMPUWrapper from '../../components/Ads/SidebarMPU/SidebarMPUWrapper';
import TopBanner from '../../components/PromotionalBanners/TopBanner';
import JoinTSRBanner from '../../components/PromotionalBanners/JoinTSRBanner';
import AppReminder from '../../components/PromotionalBanners/AppReminder';
import QuoteCarousel from '../../components/QuoteCarousel';
import HowDoesTSRWork from '../../components/PromotionalBanners/HowDoesTSRWork';
import MemberBanner from '../../components/PromotionalBanners/MemberBanner';
import SeasonalBanner from './Seasonal';
import TSRPoll from '../../components/Poll/TSRPoll';
import ErrorBoundary from '../../components/ErrorBoundary';
import Watched from '../../components/Widgets/Home/Watched';
import LazyLoad from '../../components/LazyLoad';
import Quotes from './quotes';
import DataPrompt from '../../components/PromotionalBanners/DataPrompt';
import { Feed, SuggestedFollowerWidget } from '@thestudentroom/lib.discussions';

export default function () {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const userId = useSelector((state: RootState) => state.user.userId);
    const pageTargeting = useSelector((state: RootState) => state.adverts.pageTargeting);
    const modal = useSelector((state: RootState) => state.modals);
    const gb = useGrowthBook();
    const latestThreadsPollingInterval = useFeatureIsOn('react-new-thread-polling') ? 30000 : 0;

    function handleClickSignUp() {
        dispatch(showModal(true, 'homefeedsignup', null, true));
    }

    useEffect(() => {
        setAdvertTargeting(dispatch);
        setPageTracking(gb);
    }, []);

    const metaDescription = "The Student Room is the largest online community for school, college and uni students in the UK. Find support on everything from GCSEs and A-levels to uni life."

    return (
        <Fragment>
            <Helmet>
                <title>The Student Room</title>
                <link rel="canonical" href={runtimeConfig.appDomain} />
                <meta name="description" content={metaDescription}></meta>
            </Helmet>
            {userId == 0 && (
                <MainBannerContainer>
                    <PromoBlockInnerContainer>
                        <TopBanner
                            mainText={`We're The Student Room: the UK's largest online community for school, college and uni students.`}
                            title="Discuss and discover, together."
                            subText={` Find friendly and supportive discussions on everything from GCSEs to uni life, from A-levels to Ucas applications.`}
                        />
                    </PromoBlockInnerContainer>
                </MainBannerContainer>
            )}

            <LayoutInner style={{ justifyContent: 'center' }}>
                <PromoBlockInnerContainer>
                    <LayoutContentInner>
                        <MainContent style={{ gap: '9px' }}>
                            <Feed
                                latestThreadsPollingOn={!modal.showModal}
                                latestThreadsPollingInterval={latestThreadsPollingInterval}
                                numberOfDiscussions={25}
                                userId={userId}
                                onSignUp={handleClickSignUp}
                                promotionalItem={userId > 0 && showExistingMemberDPC(user) ? <DataPrompt /> : undefined}
                                showPromotionalItemEvery={10}
                            />

                            {userId > 0 ? (
                                <>
                                    <LazyLoad key={'lazyload-follow-suggestions'} height={400} offset={300}>
                                        <SuggestedFollowerWidget currentUserId={userId} />
                                    </LazyLoad>
                                    <LazyLoad key={'lazyload-watched'} height={500} offset={300}>
                                        <Watched />
                                    </LazyLoad>
                                    <LazyLoad key={'lazyload-member-banner'} height={200} offset={300}>
                                        <MemberBanner />
                                    </LazyLoad>
                                </>
                            ) : null}
                        </MainContent>

                        <Sidebar>
                            <ErrorBoundary key={'sidebar-hpu-errorBoundary'}>
                                {pageTargeting.p_t && <SidebarHPUWrapper key={'sidebar-hpu'} />}
                            </ErrorBoundary>
                            <ErrorBoundary key={'poll-widget-errorBoundary'}>
                                <TSRPoll key={'poll-widget'} />
                            </ErrorBoundary>
                            <ErrorBoundary key={'sidebar-mpu-errorBoundary'}>
                                {pageTargeting.p_t && <SidebarMPUWrapper key={'sidebar-mpu'} />}
                            </ErrorBoundary>
                        </Sidebar>
                    </LayoutContentInner>
                </PromoBlockInnerContainer>
            </LayoutInner>

            {userId == 0 ? (
                <>
                    <QuoteBannerContainer>
                        <PromoBlockInnerContainer style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <QuoteCarousel
                                title={t(`home.Banners.quoteCarousel.mobileTitle`)}
                                items={Quotes}
                            />
                        </PromoBlockInnerContainer>
                    </QuoteBannerContainer>

                    <LayoutInner style={{ justifyContent: 'center' }}>
                        <PromoBlockInnerContainer>
                            <HowDoesTSRWork />
                        </PromoBlockInnerContainer>
                    </LayoutInner>
                </>
            ) : null}

            <SeasonalContainer>
                <PromoBlockInnerContainer>
                    <SeasonalBanner />
                </PromoBlockInnerContainer>
            </SeasonalContainer>

            <AppReminderContainer>
                <AppReminder />
            </AppReminderContainer>

            {userId == 0 ? (
                <JoinContainer>
                    <PromoBlockInnerContainer>
                        <JoinTSRBanner
                            secondaryText={true}
                            bulletPoints={[
                                'home.Banners.Signup.heading-1',
                                'home.Banners.Signup.heading-2',
                                'home.Banners.Signup.heading-3',
                                'home.Banners.Signup.heading-4',
                                'home.Banners.Signup.heading-5'
                            ]}
                        />
                    </PromoBlockInnerContainer>
                </JoinContainer>
            ) : null}
        </Fragment>
    );
}
