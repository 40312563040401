export const LeftSeasonalLinks = [
    {
        index: '1',
        titleKey: 'home.seasonal.link-one',
        url: '/forumdisplay.php?f=11'
    },
    {
        index: '2',
        titleKey: 'home.seasonal.link-two',
        url: '/forumdisplay.php?f=1659'
    },
    {
        index: '3',
        titleKey: 'home.seasonal.link-three',
        url: '/showthread.php?t=7508490'
    }
];

export const RightSeasonalLinks = [
    {
        index: '4',
        titleKey: 'home.seasonal.link-four',
        url: '/showthread.php?t=7507723'
    },
    {
        index: '5',
        titleKey: 'home.seasonal.link-five',
        url: '/showthread.php?t=7508682'
    }
];
