import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';

export const TopicContainer = styled.div`
    height: 40px;
    overflow-y: hidden;
`;

export const TopicList = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: ${(props) => props.theme.spacing(3)};
    height: 60px;
`;

export const Topic = styled(Chip)<{ selected: boolean }>`
    background-color: ${(props) => props.selected ? props.theme.palette.secondary.main : props.theme.palette.secondary.light};
    ${(props) => props.theme.typography.subtitle2};
    color: ${(props) => props.theme.palette.secondary.contrastText};

    &:hover {
        background-color: ${(props) => props.theme.palette.secondary.dark};
    }
`;
