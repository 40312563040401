import { t } from '../../hooks/useTranslation';

export default [
    {
        text: t('home.Banners.quoteCarousel.Quote-1.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-1.label'),
    },
    {
        text: t('home.Banners.quoteCarousel.Quote-2.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-2.label'),
    },
    {
        text: t('home.Banners.quoteCarousel.Quote-3.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-3.label'),
    },
    {
        text: t('home.Banners.quoteCarousel.Quote-4.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-4.label'),
    },
    {
        text: t('home.Banners.quoteCarousel.Quote-5.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-5.label'),
    },
    {
        text: t('home.Banners.quoteCarousel.Quote-6.description'),
        subtext: t('home.Banners.quoteCarousel.Quote-6.label'),
    }
];
