import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const SeasonalContainer = styled.div`
    background-color: ${(props) => props.theme.palette.info.main};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing(3)};

    @media (max-width: 980px) {
        flex-direction: column;
        align-items: center;
        padding-top: ${(props) => props.theme.spacing(5)};
    }
`;

export const SeasonalImage = styled.img`
    width: 363px;
    margin-left: ${(props) => props.theme.spacing(-5)};

    @media (max-width: 980px) {
        width: 320px;
        margin-left: ${(props) => props.theme.spacing(0)};
    }  
`;

export const SeasonalContent = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 981px) {
        margin-left: ${(props) => props.theme.spacing(6)};
        width: calc(100% - 380px);
    }

    @media (min-width: 1100px) {
        width: 800px;
    }
`;

export const SeasonalHeader = styled.h2`
    font-family: Arial;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.84px;
    margin: ${(props) => props.theme.spacing(0, 0, 2, 0)};
`;

export const SeasonalSubHeader = styled.p`
    ${(props) => props.theme.typography.body1};
    margin: ${(props) => props.theme.spacing(0)};
`;

export const SeasonalLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing(3)};

    @media (max-width: 980px) {
        flex-direction: column;
        margin-top: ${(props) => props.theme.spacing(2)};
    }
`;

export const SeasonalLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.theme.spacing(2)};
    gap: ${(props) => props.theme.spacing(3)};
    width: 50%;

    @media (max-width: 980px) {
        margin-top: ${(props) => props.theme.spacing(0)};
        width: 100%;
    }
`;

export const SeasonalLink = styled(Button)`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.white};
    padding: ${(props) => props.theme.spacing(1, 13)};
    align-items: center;
    gap: ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(3)};
    margin-right: ${(props) => props.theme.spacing(3)};
    text-transform: none;
    color: ${(props) => props.theme.grey.dark};
    ${(props) => props.theme.typography.subtitle1};
    display: inline-flex;
    justify-content: space-between;

    &:hover {
        background-color: ${(props) => props.theme.white};
        text-decoration: underline;
        text-decoration-color: ${(props) => props.theme.palette.primary.main};
        text-decoration-thickness: 2px;
    }

    @media (max-width: 980px) {
        padding: ${(props) => props.theme.spacing(2, 5)};
        margin-right: ${(props) => props.theme.spacing(0)};
    }
`;

export const SeasonalLinkText = styled.p`
    color: ${(props) => props.theme.grey.dark};
    ${(props) => props.theme.typography.subtitle1};
`;

export const Desktop = styled.div`
    display: block;

    @media (max-width: 980px) {
        display: none;
    }
`;

export const Mobile = styled.div`
    display: block;

    @media (min-width: 981px) {
        display: none;
    }
`;
