import styled from '@emotion/styled';

export const Content = styled.div`
    padding: ${props => props.theme.spacing(6, 5)};
    background-color: ${props => props.theme.grey.dark};
    color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing(4)};
`;

export const AppButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AppButtonIcon = styled.img`
    width: ${props => props.theme.spacing(9)};
    margin-right: ${props => props.theme.spacing(4)};
`;

export const PromptText = styled.p`
    margin: 0;
    width: 100%;
    ${props => props.theme.typography.subtitle2};
`;

export const ContinueButton = styled.p`
    align-self: center;
    margin: 0;
    ${props => props.theme.typography.body2};
`;
