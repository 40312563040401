import { useQuery as useGraphQLQuery } from '@apollo/client';
/* API */
import { GET_THREAD, GET_THREAD_PAGE, GET_FIRST_POST } from '../../../api/Thread/graph';
import { GET_IGNORE_LIST } from '../../../api/User/graph';

export default function (
    threadId: number,
    pageNumber: number | null,
    isMember: boolean,
    bypassCache: boolean
) {
    const threadData = useGraphQLQuery(GET_THREAD, {
        variables: { threadId: threadId, isMember },
        errorPolicy: 'all',
        ssr: !bypassCache,
        skip: threadId === 0,
        fetchPolicy: bypassCache ? 'network-only' : 'cache-first',
        context: {
            batch: false
        }
    });

    const firstPostData = useGraphQLQuery(GET_FIRST_POST, {
        variables: { threadId: threadId, isMember },
        errorPolicy: 'all',
        ssr: !bypassCache,
        skip: threadId === 0,
        fetchPolicy: bypassCache ? 'network-only' : 'cache-first'
    });

    const postData = useGraphQLQuery(GET_THREAD_PAGE, {
        variables: { threadId, pageNumber, isMember },
        errorPolicy: 'all',
        ssr: !bypassCache,
        skip: threadId === 0 || pageNumber === null,
        fetchPolicy: bypassCache ? 'network-only' : 'cache-first'
    });

    const ignoreListData = useGraphQLQuery(GET_IGNORE_LIST, {
        variables: { threadId, pageNumber, isMember },
        errorPolicy: 'all',
        ssr: !bypassCache,
        skip: threadId === 0 || pageNumber === null || !isMember,
        fetchPolicy: bypassCache ? 'network-only' : 'cache-first'
    });

    return { threadData, firstPostData, postData, ignoreListData };
}
