/* APIs */
import { GET_WATCHED_THREADS } from '../../../../api/Thread/graph';
import { GET_WATCHED_THREAD_IDS } from '../../../../api/Notification/graph';
import { useQuery as useGraphQLQuery } from '@apollo/client';

/* Components */
import DiscussionWidgetTemplate from '../DiscussionWidgetTemplate';

interface WatchedThreadsProps {
    topicSelector: any;
}

export default function WatchedThreads({ topicSelector }: WatchedThreadsProps) {
    const { loading: watchedThreadIdLoading, data: watchedThreadIdData } = useGraphQLQuery(
        GET_WATCHED_THREAD_IDS,
        {
            variables: {},
            errorPolicy: 'all',
            ssr: true,
            fetchPolicy: 'no-cache'
        }
    );

    const { loading: watchedThreadsLoading, data: watchedThreadsData } = useGraphQLQuery(GET_WATCHED_THREADS, {
        variables: {
            perPage: 5,
            page: 1,
            orderBy: 'date',
            orderByDirection: 'desc',
            filters: [
                {
                    key: 'thread_ids',
                    value: watchedThreadIdData?.watched_thread_list?.watched?.join(',') ?? ''
                },
                { key: 'thread_filter', value: 'unread' },
                { key: 'include_forums', value: '1' }
            ]
        },
        fetchPolicy: 'no-cache',
        skip: watchedThreadIdLoading || !watchedThreadIdData || watchedThreadIdData?.watched_thread_list?.watched?.length <= 0,
        errorPolicy: 'all',
        ssr: true
    });

    return (
        <DiscussionWidgetTemplate
            title={'watched-threads'}
            loading={watchedThreadIdLoading || watchedThreadsLoading}
            discussionListData={watchedThreadsData}
            topicSelector={topicSelector}
        />
    );
}
