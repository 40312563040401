import {
    SeasonalContainer,
    SeasonalImage,
    SeasonalContent,
    SeasonalHeader,
    SeasonalSubHeader,
    SeasonalLinks,
    SeasonalLinksContainer,
    Desktop,
    Mobile
} from './styles';
import useTranslation from '../../../hooks/useTranslation';
import { LeftSeasonalLinks, RightSeasonalLinks } from './items';
import { MdChevronRight } from 'react-icons/md';
import { runtimeConfig } from '../../../runtimeConfig';
import { PromotionalButton } from '@thestudentroom/lib.themes';
import styled from '@emotion/styled';

export default function Seasonal() {
    const { t } = useTranslation();

    return (
        <SeasonalContainer>
            <SeasonalImage
                src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/university.svg'}
            />

            <SeasonalContent>
                <SeasonalHeader>{t('home.seasonal.heading')}</SeasonalHeader>

                <Desktop>
                    <SeasonalSubHeader>{t('home.seasonal.sub-heading-one')}</SeasonalSubHeader>
                    <SeasonalSubHeader>{t('home.seasonal.sub-heading-two')}</SeasonalSubHeader>
                </Desktop>
                <Mobile>
                    <SeasonalSubHeader>{`${t('home.seasonal.sub-heading-one')} ${t(
                        'home.seasonal.sub-heading-two'
                    )}`}</SeasonalSubHeader>
                </Mobile>

                <SeasonalLinksContainer>
                    <SeasonalLinks>
                        {LeftSeasonalLinks.map((item, index) => {
                            return (
                                <SeasonalButton
                                    key={`seasonal-link-${index}`}
                                    linkText={t(item.titleKey)}
                                    linkUrl={item.url}
                                />
                            );
                        })}
                    </SeasonalLinks>

                    <SeasonalLinks>
                        {RightSeasonalLinks.map((item, index) => {
                            return (
                                <SeasonalButton
                                    key={`seasonal-link-${index}`}
                                    linkText={t(item.titleKey)}
                                    linkUrl={item.url}
                                />
                            );
                        })}
                    </SeasonalLinks>
                </SeasonalLinksContainer>
            </SeasonalContent>
        </SeasonalContainer>
    );
}

function SeasonalButton(props: { linkText: string; linkUrl: string }) {
    const PromoButton = styled(PromotionalButton)`
        &:hover {
            svg {
                color: ${(props) => props.theme.palette.primary.main};
            }
        }
    `;

    return (
        <PromoButton
            href={props.linkUrl}
            aria-label={props.linkText}
            endIcon={<MdChevronRight size="30" />}
        >
            {props.linkText}
        </PromoButton>
    );
}
