/* React */
import { ReactNode } from 'react';

/* API */
import { DiscussionWidgetThread } from '../../../types/thread';

/* Styles */
import {
    ThreadListWidget,
    ThreadListTitle,
    ThreadListTitleContainer,
    WidgetButtonContainer
} from './styles';
import { DiscussionCardContainer } from '../../Discussion/DiscussionCard/styles';

/* Components */
import DiscussionCard from '../../Discussion/DiscussionCard';
import DiscussionCardSkeleton from '../../Discussion/DiscussionCard/DiscussionCardSkeleton';
import WatchButton from '../../WatchButton';
import ErrorBoundary from '../../ErrorBoundary';
import { PrimaryButton } from '@thestudentroom/lib.themes';

/* Hooks */
import useTranslation from '../../../hooks/useTranslation';

interface DiscussionWidgetTemplateProps {
    title: 'watched-threads' | 'watched-topics' | 'following';
    loading: boolean;
    discussionListData: any;
    topicSelector?: ReactNode;
}

export default function DiscussionWidgetTemplate(props: DiscussionWidgetTemplateProps) {
    const { t } = useTranslation();

    const emptyText = t(`home.widgets.no-${props.title}-results`);
    const widgetTitle = ['watched-threads', 'watched-topics'].includes(props.title) ? 'watching' : props.title;

    return (
        <>
            <ThreadListWidget>
                <ThreadListTitleContainer>
                    <ThreadListTitle>{widgetTitle}</ThreadListTitle>
                </ThreadListTitleContainer>

                {props.topicSelector}

                <ErrorBoundary errorComponent={<Loading type={props.title.toLowerCase()} />}>
                    <DiscussionWidgetContent
                        title={props.title}
                        loading={props.loading}
                        discussionListData={props.discussionListData}
                        emptyText={emptyText}
                    />
                </ErrorBoundary>
            </ThreadListWidget>

            {props.title !== 'watched-topics' &&
                <WidgetButtonContainer>
                    <PrimaryButton
                        onClick={() => {
                            switch (props.title) {
                                case 'watched-threads':
                                    // replace these with navigate once these get swapped to react pages
                                    window.location.href = '/subscription.php';
                                    break;
                                case 'following':
                                    window.location.href = '/follow.php';
                                    break;
                            }
                        }}
                    >
                        {t(`home.widgets.view-all`)}
                    </PrimaryButton>
                </WidgetButtonContainer>
            }
        </>
    );
}

const DiscussionWidgetContent = ({
    title,
    loading,
    discussionListData,
    emptyText
}: {
    title: string;
    loading: boolean;
    discussionListData: any;
    emptyText: string;
}) => {
    if (loading) {
        return <Loading type={title.toLowerCase()} />;
    }

    let discussionList = [];
    if (discussionListData && title.toLowerCase() == 'watched-topics') {
        // Reshape watched forum data to match the widget format
        const watchedTopicThreads = discussionListData.watched_topic_list?.watched?.watched_topic ?? [];

        watchedTopicThreads.forEach((watchedTopicData: any) => {
            if (watchedTopicData.latest_thread && watchedTopicData.latest_thread.id) {
                discussionList.push({
                    "id": watchedTopicData.latest_thread.id,
                    "title": watchedTopicData.latest_thread.title,
                    "n_posts": watchedTopicData.latest_thread.n_posts,
                    "subscribers": watchedTopicData.latest_thread.subscribers,
                    "first_post": watchedTopicData.latest_thread.first_post,
                    "last_post": {
                        "created_at": watchedTopicData.latest_thread.last_post.created_at
                    },
                    "topics": [],
                    "forums": [
                        {
                            "id": watchedTopicData.id,
                            "title": watchedTopicData.title,
                            "__typename": "Forum"
                        }
                    ]
                });
            }
        });
    }
    else if (discussionListData && discussionListData.threads) {
        discussionList = discussionListData.threads;
    }
    
    if (discussionList?.length <= 0) {
        return (
            <DiscussionCardContainer>
                <p>{emptyText}</p>
            </DiscussionCardContainer>
        );
    } 
    else {
        return (
            <>
                {discussionList.map((thread: DiscussionWidgetThread, index: number) => (
                    <ErrorBoundary key={`discussion-${thread.id}-errorBoundary`}>
                        <DiscussionCard
                            key={`${title.toLowerCase()}-${thread.id}`}
                            index={index}
                            thread={thread}
                            actionButton={<AdditionalButton title={title} thread={thread} />}
                            type={title}
                        />
                    </ErrorBoundary>
                ))}
            </>
        );
    }
};

const AdditionalButton = ({ title, thread }: { title: string; thread: DiscussionWidgetThread }) => {
    let additionalButton = null;
    switch (title.toLowerCase()) {
        case 'watched-threads':
            additionalButton = (
                <WatchButton
                    watchType={'thread'}
                    threadId={thread.id}
                    forumId={thread.forumid ?? 0}
                    forumCategoryId={0}
                    replyCount={thread.n_posts}
                    watched={true}
                />
            );
    }

    return additionalButton;
};

export const Loading = ({ type }: { type: string }) => {
    return (
        <>
            {[...Array(5)].map((item, index) => {
                return <DiscussionCardSkeleton key={`${type}-skeleton-${index}`} />;
            })}
        </>
    );
};
