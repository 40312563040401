import LazyLoad from '../../LazyLoad';
import useTranslation from '../../../hooks/useTranslation';
import {
    HowDoesTSRWorkContainer,
    HowDoesTSRWorkTitle,
    HowDoesTSRWorkText,
    HowDoesTSRWorkTextContainer,
    HowDoesTSRWorkVideoContainer,
    HowDoesTSRWorkVideo,
    HowDoesTSRWorkOuterContainer,
    BottomLeftBubble,
    TopRightBubble,
    BottomMiddleBubble
} from './styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

export default function HowDoesTSRWork() {
    const { t } = useTranslation();
    const theme = useAppTheme();
    return (
        <>
            <HowDoesTSRWorkOuterContainer>
                <HowDoesTSRWorkContainer>
                    <BottomLeftBubble
                        topLeft={true}
                        bottomLeft={true}
                        bottomRight={true}
                        bgColor={theme.palette.tertiary.main}
                        radius={10}
                        height={100}
                        width={300}
                    />

                    <TopRightBubble
                        topLeft={true}
                        bottomRight={true}
                        bgColor={theme.blue.richBlue}
                        bgTextured={"striped"}
                        radius={4}
                        height={300}
                        width={250}
                    />

                    <BottomMiddleBubble
                        bottomLeft={true}
                        bottomRight={true}
                        borderColor={theme.palette.info.main}
                        radius={15}
                        height={150}
                        width={400}
                    />

                    <HowDoesTSRWorkTextContainer>
                        <HowDoesTSRWorkTitle>
                            {' '}
                            {t('home.Banners.howDoesTSRWork.heading')}{' '}
                        </HowDoesTSRWorkTitle>
                        <HowDoesTSRWorkText>
                            <p>{t('home.Banners.howDoesTSRWork.mainText')}</p>
                            <p>{t('home.Banners.howDoesTSRWork.secondaryText')} </p>
                            <p>{t('home.Banners.howDoesTSRWork.tertiaryText')} </p>
                        </HowDoesTSRWorkText>
                    </HowDoesTSRWorkTextContainer>
                    <LazyLoad style={{ zIndex: 1 }}>
                        <HowDoesTSRWorkVideoContainer>
                            <HowDoesTSRWorkVideo
                                controls
                                autoPlay
                                loop
                                muted
                                playsInline
                                src="https://public.thestudentroom.co.uk/misc/Welcome_TSR.mp4"
                            >
                                <source
                                    src="https://public.thestudentroom.co.uk/misc/Welcome_TSR.mp4"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </HowDoesTSRWorkVideo>
                        </HowDoesTSRWorkVideoContainer>
                    </LazyLoad>
                </HowDoesTSRWorkContainer>
            </HowDoesTSRWorkOuterContainer>
        </>
    );
}
