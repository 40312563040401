import { Skeleton } from '@mui/material';
import styled from '@emotion/styled';

/* Components & Styles */
import { PostHeaderContainer, PostOuterContainer, PostDetailsContainer } from '../Post/styles';
import { LayoutContentInner, Sidebar } from '../../Layouts/Main/styles';
import { ThreadTitleContainer, ThreadTitleHeader } from '../Thread/Title/styles'
import LoadingDiscussionsWidget from '../Widgets/DiscussionsWidget/loading';

const LoadingFlexContainer = styled.div`
    display: flex;
`;

const LoadingUserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.theme.spacing(3)};
`;

export const LoadingUserInfo = () => {
    return (
        <LoadingFlexContainer>
            <Skeleton
                variant="circular"
                sx={{
                    width: '40px',
                    height: '40px',
                    '@media screen and (min-width: 671px)': {
                        width: '46px',
                        height: '46px'
                    }
                }}
            />
            <LoadingUserInfoContainer>
                <Skeleton variant="text" width={100} height={20} />
                <LoadingFlexContainer>
                    <Skeleton variant="text" width={70} height={20} />
                </LoadingFlexContainer>
            </LoadingUserInfoContainer>
        </LoadingFlexContainer>
    );
};

export const LoadingPost = () => {
    return (
        <div key={`skeleton`}>
            <LoadingUserInfo />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </div>
    );
};

export const LoadingPage = () => {
    return <p>Loading...</p>;
};

export const LoadingFirstPost = () => {
    return (
        <PostOuterContainer key={`loading-container-op`} style={{ marginBottom: '18px' }}>
            <PostHeaderContainer>
                <ThreadTitleContainer style={{ height: '100px', marginBottom: '15px' }} isThreadOpen={true}>
                    <ThreadTitleHeader>
                        <Skeleton variant="text" style={{ width: '150px', height: '30px', marginTop: '10px' }} />
                                
                        <Skeleton variant="text" style={{ width: '100px', height: '20px', marginTop: '10px' }} />
                    </ThreadTitleHeader>

                    <Skeleton variant="text" style={{ width: '250px', height: '40px', marginTop: '5px' }} />
                </ThreadTitleContainer>
            </PostHeaderContainer>

            <LoadingPost />
        </PostOuterContainer>
    )
}

export const LoadingReply = (props: { postIndex: number, index: number }) => {
    return (
        <PostOuterContainer key={`loading-container-${props.postIndex}-${props.index}`} style={{ marginBottom: '18px' }}>
            <PostDetailsContainer style={{ marginBottom: '10px' }} isThreadOpen={true}>
                <Skeleton variant="text"  style={{ width: '60px', height: '25px' }} />
                <Skeleton variant="text"  style={{ width: '80px', height: '25px' }} />    
            </PostDetailsContainer>
            <LoadingPost key={`loading-post-${props.postIndex}-${props.index}`} />
        </PostOuterContainer>
    )
}

export const LoadingThread = (props: { numColumns: number }) => {
    return (
        <div style={{ width: '100%', gridColumn: `span ${props.numColumns}` }}>
            <LoadingFirstPost />
                    
            {[...Array(4)].map((postIndex: number, index: number) => (
                <LoadingReply postIndex={postIndex} index={index} />
            ))}
        </div>
    );
};

export const LoadingPageFull = (props: { showSidebar: boolean }) => {
    return (
        <LayoutContentInner>
            <LoadingThread numColumns={props.showSidebar ? 1 : 2} />

            {props.showSidebar &&
                <Sidebar>
                    <LoadingDiscussionsWidget
                        standalone={true}
                        threadsPerPage={10}
                        title=""
                        variant="card"
                    />
                </Sidebar>
            }
        </LayoutContentInner>
    );
};
