import styled from '@emotion/styled';
import { DiscussionBubble } from '@thestudentroom/lib.themes';

export const HowDoesTSRWorkContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.palette.primary.light}50;
    display: flex;
    border-radius: 16px;
    flex-direction: row;
    justify-content: space-between;
    padding: ${(props) => props.theme.spacing(5)};

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const HowDoesTSRWorkOuterContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%
    background: unset;
    background-color: unset;
    display: flex;
    flex-direction: column;
    padding-bottom: ${(props) => props.theme.spacing(2)};

    @media (min-width: 768px) {
        height: 390px;
        flex-direction: row;
    }
`;

export const HowDoesTSRWorkTitle = styled.h2`
    ${(props) => props.theme.text.subtitle2};
    line-height: 38px;
    text-transform: none;
    font-weight: 700;
    z-index: 1;
    font-size: 26px;
    margin: 0;
`;

export const HowDoesTSRWorkText = styled.div`
    width: 100%;
    z-index: 1;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 980px) {
        text-align: left;
    }
`;

export const HowDoesTSRWorkVideoContainer = styled.div`
    padding: 0;
    display: block;
    z-index: 1;
    width: 390px;
    height: 226px;
    
    margin-left: 1vw;
    box-shadow: none;
    border: 5px solid;
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    border-color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main};
    align-self: center;

    @media (max-width: 350px) {
        height: 151px;
        width: 279px;
    }

    @media (min-width: 768px) {
        align-self: unset;
    }

    @media (min-width: 1025px) {
        width: 585px;
        height: 339px;
    }
`;

export const HowDoesTSRWorkTextContainer = styled.div`
    text-align: left;
    width: 100%;
    z-index: 1;
    display: block;

    @media (min-width: 768px) {
        width: 350px;
    }

    @media (min-width: 1280px) {
        width: 500px;
    }
`;

export const HowDoesTSRWorkVideo = styled.video`
    z-index: 1;
    height: 100%;

    @media (max-width: 350px) {
        height: 151px;
        width: 269px;
    }

    @media (max-width: 980px) {
        max-height: 237px;
    }
`;

export const BottomLeftBubble = styled(DiscussionBubble)`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 !important;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const TopRightBubble = styled(DiscussionBubble)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const BottomMiddleBubble = styled(DiscussionBubble)`
    position: absolute;
    bottom: 0;
    left: 35%;
    padding: 0 !important;
    border-bottom: 0 !important;

    @media (max-width: 1025px) {
        display: none;
    }
`;
