/* Libraries */
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet-async';
import useTranslation from '../../hooks/useTranslation';
import Typography from '@mui/material/Typography';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react';
import { useLocation } from 'react-router-dom';
import Pusher from 'pusher-js';
import styled from '@emotion/styled';

/* Components and Wrappers */
import { LoadingPageFull } from '../../components/Loading';
import VolunteerTeamWidgetWrapper from '../../components/Widgets/VolunteerTeamWidget/VolunteerTeamWidgetWrapper';
import SidebarHPUWrapper from '../../components/Ads/SidebarHPU/SidebarHPUWrapper';
import SidebarMPUWrapper from '../../components/Ads/SidebarMPU/SidebarMPUWrapper';
import AnnouncementsBar from '../../components/AnnouncementsBar';

/* Helpers / Hooks */
import useQuery from '../../hooks/useQuery';
import useThreadData from './Hooks/useThreadData';
import getMetaData from './Hooks/useMetaData';
import setAdvertTargeting from './Helpers/setAdvertTargeting';
import setPageTracking from './Helpers/setPageTracking';
import scrollToPost from './Helpers/scrollToPost';
import RedirectWithStatus from '../../helpers/RedirectWithStatus';
import { Forum } from '../../types/thread';
import { RootState } from '../../client';
import { runtimeConfig } from '../../runtimeConfig';
import appDownloadPromptCheck from '../../helpers/appDownloadPromptCheck';
import getPartnershipBlock from './Helpers/getPartnershipBlock';
import getBrandAwarenessBlock from './Helpers/getBrandAwarenessBlock';
import { PartnershipBlock, BrandAwarenessBlock } from '@thestudentroom/lib.content';
import type { BrandAwarenessBlock as BrandAwarenessBlockType, PartnershipBlock as PartnershipBlockType } from '../../types/learning_provider';

/* Styling */
import {
    LayoutInner,
    LayoutContentInner,
    FullWidthCenteredContainer,
    MainContent,
    Sidebar,
    PrimaryWidgets,
    SecondaryWidgets,
    LeftSidePanel,
    RightSidePanel,
    LayoutContent
} from '../../Layouts/Main/styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

// TODO: remove updateForumId when we have a proper forum suggestion
import { updateForumId, updateAppDownloadCheck } from '../../store/thread/actions';
import { setOwnership } from '../../store/adverts/actions';

/* Components */
import ErrorBoundary from '../../components/ErrorBoundary';
import GoToNewPost from './GoToNewPost';
import GoToPost from './GoToPost';
import AppDownloadPrompt from '../../components/AppDownloadPrompt';

const Thread = loadable(() => import('../../components/Thread'));
const DiscussionsWidget = loadable(() => import('../../components/Widgets/DiscussionsWidget'));
const RelatedDiscussionsWidget = loadable(
    () => import('../../components/Widgets/RelatedDiscussionsWidget')
);
const RelatedArticlesWidget = loadable(
    () => import('../../components/Widgets/RelatedArticlesWidget')
);
const Breadcrumb = loadable(() => import('../../components/Breadcrumb'));
const TSRPoll = loadable(() => import('../../components/Poll/TSRPoll'));
const ErrorPage = loadable(() => import('../Error'));
const ShowthreadVersionToggle = loadable(
    () => import('../../components/Buttons/ShowthreadVersionToggle')
);

const Desktop = styled.div`
    display: none;

    @media (min-width: 981px) {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
`;

// Create our component
export default () => {
    const theme = useAppTheme();
    const pageTargeting = useSelector((state: RootState) => state.adverts.pageTargeting);
    // From query string
    const query: any = useQuery();
    const { hash } = useLocation();
    let threadId = parseInt(query.get('t') || '0');
    const pageNumber = parseInt(query.get('page') || '1');
    const gotoNewPost = threadId > 0 && query.has('goto') && query.get('goto') === 'newpost';
    const gotoLastPost = threadId > 0 && query.has('goto') && query.get('goto') === 'lastpost';
    const hasPageNumber = gotoNewPost ? false : query.has('page') && query.get('page') !== '0';
    const postId = parseInt(query.get('p') || '0');
    const showNewPostDivider: boolean = !!parseInt(query.get('viewing_newpost') || '0');
    const bypassCache: boolean = showNewPostDivider;

    const displayOwnershipBlocks = useFeatureIsOn('learning-provider-ownership-blocks') ?? false;
    const displayBrandAwarenessBlocks = useFeatureIsOn('learning-provider-awareness-blocks') ?? false;
    const [brandAwarenessBlock, setBrandAwarenessBlock] = useState<BrandAwarenessBlockType | null>(null);
    const [partnershipBlock, setPartnershipBlock] = useState<PartnershipBlockType | null>(null);
    const hasOwnership = useSelector((state: RootState) => state.adverts.ownership_learning_provider);

    // App download prompt test
    const appPromptTestEnabled = useFeatureIsOn('app-prompt-after-reply');
    const doAppDownloadCheck = useSelector((state: RootState) => state.thread.appDownloadCheck);
    const bottomSheetActive = useSelector((state: RootState) => state.adverts.bottomSheetActive);
    const [showAppPrompt, setShowAppPrompt] = useState(false);

    const { t } = useTranslation();
    const gb = useGrowthBook();
    const userId = useSelector((state: RootState) => state.user.userId);
    const reduxDispatch = useDispatch();
    let currentForum: any = null;
    let currentForumCategory: any = null;

    // Get data required for this page
    const isMember = userId > 0;
    const { threadData, firstPostData, postData, ignoreListData } = useThreadData(
        gotoNewPost ? 0 : threadId,
        pageNumber,
        isMember,
        bypassCache
    );

    // Extract the state of our thread page data
    const thread = threadData.data && threadData.data.thread ? threadData.data.thread : null;

    useEffect(() => {
        if (thread?.id && pageNumber) {
            setPageTracking(gb, thread, pageNumber);
            setAdvertTargeting(reduxDispatch, thread, pageNumber);
        }

        if (thread?.id && displayOwnershipBlocks) {
            if (thread?.learning_providers?.length) {
                // Clear the ownership state before setting it again to make sure we can trigger a visible event on every page view
                reduxDispatch(setOwnership(null));
                setTimeout(() => {
                    reduxDispatch(setOwnership(thread?.learning_providers[0]));
                    setBrandAwarenessBlock(null);
                    setPartnershipBlock(null);
                }, 100);
            } else {
                reduxDispatch(setOwnership(null));
                setOtherBlocks();
            }
        } else if (thread?.id) {
            reduxDispatch(setOwnership(null));
            setOtherBlocks();
        }
    }, [thread?.id, pageNumber]);

    function setOtherBlocks() {
        if (thread?.id && displayBrandAwarenessBlocks) {
            setBrandAwarenessBlock(getBrandAwarenessBlock(thread?.brand_awareness_blocks ?? []));
        }

        if (thread?.id) {
            setPartnershipBlock(getPartnershipBlock(thread?.partnership_blocks ?? []));
        }
    }

    useEffect(() => {
        if (currentForum?.id) {
            reduxDispatch(updateForumId(currentForum.id));
        }
    }, [currentForum]);

    useEffect(() => {
        if (hash) {
            scrollToPost();
            const userAgent = navigator.userAgent;
            const isMobile = /iPad|iPhone|iPod/.test(userAgent) || /android/i.test(userAgent);
            if (isMobile && doAppDownloadCheck && appPromptTestEnabled) {
                setTimeout(() => {
                    if (bottomSheetActive === false) {
                        if (appDownloadPromptCheck()) {
                            setShowAppPrompt(true);
                        }
                    }
                }, 3000);
            }
        }
        reduxDispatch(updateAppDownloadCheck(false));
    }, [hash]);

    // set up pusher instance for the page
    const [pusher, setPusher] = useState<Pusher | null>(null);

    useEffect(() => {
        if (isMember && !pusher) {
            const pusherInstance = new Pusher(runtimeConfig.pusherKey, {
                cluster: runtimeConfig.pusherCluster,
                forceTLS: true
            });

            // initially disconnect pusher, we'll connect it when we need it
            pusherInstance.disconnect();

            setPusher(pusherInstance);
        }

        // make sure we disconnect pusher when logged out
        if (!isMember && pusher) {
            pusher.disconnect();
            setPusher(null);
        }

        return () => {
            pusher?.disconnect();
        };
    }, []);

    // Build our page
    let metaData = null;
    let pageContent = null;

    if (gotoNewPost) {
        return <GoToNewPost threadId={threadId} />;
    }

    if (gotoLastPost) {
        return <GoToPost threadId={threadId} postId={thread?.last_post?.id} />
    }

    if (postId && (!gotoLastPost && !gotoNewPost && !hasPageNumber)) {
        return <GoToPost threadId={threadId} postId={postId} />;
    }

    if (threadData.loading) {
        pageContent = <LoadingPageFull showSidebar={false} key={'pageContent'} />;
    } else if (!thread || thread.error) {
        pageContent = (
            <ErrorPage key={'pageContent'} style={{ width: '100%' }} errorType={'error'} />
        );
    } else {
        switch (thread.status_code) {
            case 301:
            case 302:
                return <RedirectWithStatus status={thread.status_code} to={thread.redirect_url} />;
            case 401:
                pageContent = (
                    <ErrorPage
                        key={'pageContent'}
                        style={{ width: '100%' }}
                        errorType={'unauthorized'}
                    />
                );
                break;
            case 404:
                pageContent = (
                    <ErrorPage
                        key={'pageContent'}
                        style={{ width: '100%' }}
                        errorType={'notfound'}
                    />
                );
                break;
            case 200:
                currentForum = thread.forums[thread.forums.length - 1];
                currentForumCategory = thread.forums[0];
                metaData = getMetaData(thread, pageNumber);

                pageContent = (
                    <Thread
                        key={'pageContent'}
                        thread={thread}
                        pageNumber={pageNumber}
                        threadData={threadData}
                        firstPostData={firstPostData}
                        postData={postData}
                        ignoreListData={ignoreListData}
                        pusher={pusher || undefined}
                        showNewPostDivider={showNewPostDivider}
                        postId={postId}
                    />
                );
                break;
            default:
                pageContent = (
                    <ErrorPage key={'pageContent'} style={{ width: '100%' }} errorType={'error'} />
                );
                break;
        }
    }

    return (
        <LayoutInner key={`thread-content-${threadId}`}>
            <LeftSidePanel id={'wallpaper-panel-left'} />

            <LayoutContent>
                <Helmet>
                    <title>{metaData?.pageTitle}</title>
                    <link rel="canonical" href={metaData?.canonicalUrl} />
                    <link rel="prev" href={metaData?.prevCanonicalUrl} />
                    <link rel="next" href={metaData?.nextCanonicalUrl} />

                    <meta name="robots" content={metaData?.robotsState} />
                </Helmet>
                {thread && thread.forums && (
                    <FullWidthCenteredContainer>
                        <ErrorBoundary
                            key={'announcement-ErrorBoundary'}
                            errorComponent={
                                <Typography variant="subtitle2" color={theme.text.primary}>
                                    {t('errors.announcements')}
                                </Typography>
                            }
                        >
                            <AnnouncementsBar
                                forumIds={thread.forums.map((forum: { id: number }) => forum.id)}
                            />
                        </ErrorBoundary>
                        <ErrorBoundary
                            key={'breadCrumb-ErrorBoundary'}
                            errorComponent={
                                <Typography variant="subtitle2" color={theme.text.primary}>
                                    {t('errors.breadcrumb')}
                                </Typography>
                            }
                        >
                            <Breadcrumb
                                currentPageTitle={thread.title}
                                items={[
                                    {
                                        id: 0,
                                        title: t('breadcrumb.forums'),
                                        url: '/forum.php'
                                    }
                                ].concat(
                                    thread.forums.map((forum: Forum, index: number) => {
                                        let forumItem = { url: '', ...forum };
                                        forumItem.url = '/forumdisplay.php?f=' + forumItem.id;
                                        return forumItem;
                                    })
                                )}
                            />
                        </ErrorBoundary>
                    </FullWidthCenteredContainer>
                )}

                <ShowthreadVersionToggle />

                <LayoutContentInner>
                    <MainContent
                        itemType='https://schema.org/DiscussionForumPosting'
                        itemScope
                    >
                        {/* Structured data markup for SEO */}
                        <meta itemProp='mainEntityOfPage' content={metaData?.canonicalUrl} />
                        <meta itemProp='url' content={metaData?.canonicalUrl} />
                        {currentForum && <meta itemProp='isPartOf' content={`${runtimeConfig.appDomain}/forumdisplay.php?f=${currentForum.id}`} />}

                        {!hasOwnership && partnershipBlock ?
                            (
                                <>
                                    <PartnershipBlock
                                        image={partnershipBlock?.LearningProvider?.content?.Media?.LogoImage ?? partnershipBlock.Image ?? {
                                            url: `${runtimeConfig.publicDomain}/theme/tsr/images/logos/ident-block-colour.svg`,
                                            alt: partnershipBlock?.LearningProvider?.name,
                                            caption: partnershipBlock?.LearningProvider?.name,
                                            height: 100,
                                            width: 182
                                        }}
                                        partnerName={partnershipBlock?.LearningProvider?.name ?? partnershipBlock.PartnerName}
                                        text={partnershipBlock.Text}
                                        url={partnershipBlock?.LearningProvider?.slug ? `${runtimeConfig.uniGuideDomain}/${partnershipBlock?.LearningProvider?.slug}` : partnershipBlock.URL}
                                        tracking={{
                                            section: 'discussion',
                                            subsection: 'top'
                                        }}
                                        learningProviderId={partnershipBlock?.LearningProvider?.id ?? 0}
                                    />

                                    {partnershipBlock?.LearningProvider?.slug && <meta itemProp='significantLink' content={`${runtimeConfig.uniGuideDomain}/${partnershipBlock?.LearningProvider?.slug}`} />}
                                </>
                            ) : !hasOwnership && brandAwarenessBlock ? (
                                <>
                                    <BrandAwarenessBlock
                                        image={brandAwarenessBlock?.LearningProvider?.content?.Media?.LogoImage ?? {
                                            url: `${runtimeConfig.publicDomain}/theme/tsr/images/logos/ident-block-colour.svg`,
                                            alt: brandAwarenessBlock?.LearningProvider?.name,
                                            caption: brandAwarenessBlock?.LearningProvider?.name,
                                            height: 100,
                                            width: 182
                                        }}
                                        url={`${runtimeConfig.uniGuideDomain}/${brandAwarenessBlock?.LearningProvider?.slug}`}
                                        didYouKnows={brandAwarenessBlock?.LearningProvider?.did_you_know_facts ?? []}
                                        reviews={brandAwarenessBlock?.LearningProvider?.approved_reviews ?? []}
                                        tracking={{
                                            section: 'discussion',
                                            subsection: 'top',
                                        }}
                                        learningProviderId={brandAwarenessBlock?.LearningProvider?.id ?? 0}
                                    />

                                    {brandAwarenessBlock?.LearningProvider?.slug && <meta itemProp='significantLink' content={`${runtimeConfig.uniGuideDomain}/${brandAwarenessBlock?.LearningProvider?.slug}`} />}
                                </>
                            ) : null
                        }

                        {pageContent}
                    </MainContent>

                    <PrimaryWidgets>
                        {currentForum && (
                            <ErrorBoundary>
                                <RelatedDiscussionsWidget
                                    key={'related-discussions-content'}
                                    threadId={threadId}
                                    threadTitle={thread.title}
                                    currentForumId={currentForum.id}
                                />
                            </ErrorBoundary>
                        )}
                    </PrimaryWidgets>

                    <Sidebar>
                        <ErrorBoundary key={'discussions-widget-errorBoundary'}>
                            <DiscussionsWidget
                                threadId={threadId}
                            />
                        </ErrorBoundary>

                        <Desktop>
                            <Fragment key={`desktop-widgets-${threadId}`}>
                                <ErrorBoundary key={'sidebar-hpu-errorBoundary'}>
                                    {pageTargeting.p_t && <SidebarHPUWrapper key={'sidebar-hpu'} />}
                                </ErrorBoundary>

                                <ErrorBoundary key={'poll-widget-errorBoundary'}>
                                    <TSRPoll
                                        key={'poll-widget'}
                                        threadId={threadId}
                                        forumId={currentForum?.id}
                                        forumCategoryId={currentForumCategory?.id}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary key={'sidebar-mpu-errorBoundary'}>
                                    {pageTargeting.p_t && <SidebarMPUWrapper key={'sidebar-mpu'} />}
                                </ErrorBoundary>
                            </Fragment>
                        </Desktop>
                    </Sidebar>

                    <SecondaryWidgets>
                        {currentForum && (
                            <ErrorBoundary key={'related-articles-widget-errorBoundary'}>
                                <RelatedArticlesWidget
                                    key={'related-articles-widget'}
                                    forumId={currentForum.id}
                                    title={thread.title}
                                />
                            </ErrorBoundary>
                        )}
                        <ErrorBoundary key={'vt-widget-errorBoundary'}>
                            <VolunteerTeamWidgetWrapper key={'vt-widget'} />
                        </ErrorBoundary>
                    </SecondaryWidgets>

                    <AppDownloadPrompt
                        showPrompt={showAppPrompt}
                        setShowPrompt={setShowAppPrompt}
                        triggeredAction='reply_posted'
                    />
                </LayoutContentInner>
            </LayoutContent>

            <RightSidePanel id={'wallpaper-panel-right'} />

        </LayoutInner>
    );
};
