import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { HttpContext } from '../providers/HttpProvider';
interface RedirectWithStatusProps {
    to: string;
    status: number;
}

export default function RedirectWithStatus({ to, status }: RedirectWithStatusProps) {
    // there is no `HttpContext` on the client, so we need to guard against that here
    const ctx = useContext(HttpContext);
    if (ctx) {
        ctx.statusCode = status;
        ctx.redirectLocation = to;

        return <></>;
    }

    return <Navigate to={to} replace={true} />;
}
