import styled from '@emotion/styled';
import Link from '../Link';
import { recordVirtualPageView } from '@thestudentroom/datalayer';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface AnnouncementProps {
    title: string;
    url: string;
    slideIndex: number;
    id: number;
    target?: string;
}

const StyledAnnouncementLink = styled(Link)`
    ${(props) => props.theme.typography.caption};
    font-weight: bold;
    min-height: 41px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: none;
    letter-spacing: 0.12px;

    &:hover {
        text-decoration: none;
        -webkit-text-decoration: none;
    }

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.subtitle2};
    }
`;

export default function Announcement(props: AnnouncementProps) {
    const theme = useAppTheme();
    const sendPageViewTracking = () => {
        recordVirtualPageView(
            `/virtual/announcements-slider/slide/${props.slideIndex}/id/${props.id}?action=click`
        );
    };

    return (
        <StyledAnnouncementLink
            url={props.url}
            colour={theme.palette.secondary.contrastText}
            onClick={sendPageViewTracking}
            target={props.target}
        >
            {props.title}
        </StyledAnnouncementLink>
    );
}
