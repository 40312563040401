import { memo } from 'react';
import useTranslation from '../../../hooks/useTranslation';
import loadable from '@loadable/component';
import LazyLoad from '../../LazyLoad';
import { VolunteerTeamWidgetHeader, VolunteerTeamWidgetContainer } from './styles';

const VolunteerTeamWidget = loadable(() => import('./index'));

export default memo(() => {
    const { t } = useTranslation();

    return (
        <LazyLoad key={'lazyload-vt-widget'}>
            <VolunteerTeamWidgetContainer>
                <VolunteerTeamWidgetHeader>
                    {t('widgets.volunteer-team-header')}
                </VolunteerTeamWidgetHeader>
                <VolunteerTeamWidget />
            </VolunteerTeamWidgetContainer>
        </LazyLoad>
    );
});
