import styled from '@emotion/styled';
import Card from '@mui/material/Card';

export const AppReminderContainer = styled(Card)`
    background-color: ${(props) => props.theme.lilac.light};
    border-radius: ${(props) => props.theme.spacing(3)};
    padding-bottom: ${(props) => props.theme.spacing(3)};

    @media (min-width: 981px) {
        display: none;
    }
`;

export const AppReminderSvgContainer = styled.div`
    height: 221px;
    background-color: ${(props) => props.theme.palette.tertiary.main};
    border: 0px solid;
    justify-content: center;
    border-radius: 12px 12px 0px 0px;
    padding-top: ${(props) => props.theme.spacing(5)};
`;

export const AppReminderTitle = styled.h5`
    margin-top: 0px;
    line-height: 38px;
    ${(props) => props.theme.typography.subtitle1};
    font-size: 24px;
    margin-bottom: 0.5em;
`;

export const AppReminderText = styled.div`
    ${(props) => props.theme.typography.body1};
    width: 100%;
    line-height: 26px;
    font-size: 16px;
`;
