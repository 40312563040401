import { memo, ReactNode } from 'react';
import {
    DiscussionsWidgetContainer,
    DiscussionsWidgetContent,
} from '../styles';

export interface DiscussionsWidgetTemplateProps {
    header: ReactNode;
    children: ReactNode;
}

export default memo((props: DiscussionsWidgetTemplateProps) => {
    return (
        <DiscussionsWidgetContainer>
            {props.header}

            <DiscussionsWidgetContent>
                {props.children}
            </DiscussionsWidgetContent>
        </DiscussionsWidgetContainer>
    )
});
