import styled from '@emotion/styled';

export const ThreadTitleContainer = styled.div<{ isThreadOpen: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.isThreadOpen ? props.theme.palette.primary.light : props.theme.grey.light};
    padding: ${(props) => props.theme.spacing(0, 3)};
    border-radius: ${(props) => props.theme.spacing(1)};
    box-sizing: border-box;

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(0, 4)};
    }
`;

export const ThreadTitle = styled.h1`
    margin: ${(props) => props.theme.spacing(0, 0, 4, 0)};
    color: ${(props) => props.theme.text.primary};
    ${(props) => props.theme.typography.h2};
    display: inline;
    box-sizing: border-box;

    @media (min-width: 671px) {
        margin: ${(props) => props.theme.spacing(0, 4, 5, 0)};
    }
`;

export const ThreadTitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`;
