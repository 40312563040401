/* Libraries */
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';

/* API */
import { GET_NEW_POST } from '../../api/Thread/graph';

/* Components */
import { LoadingPageFull } from '../../components/Loading';
import RedirectWithStatus from '../../helpers/RedirectWithStatus';
import ErrorPage from '../Error';

interface GoToNewPostProps {
    threadId: number;
}

// Create our component
export default (props: GoToNewPostProps) => {    
    const threadNewPostData = useGraphQLQuery(GET_NEW_POST, {
        variables: {
            threadId: props.threadId
        },
        errorPolicy: 'all',
        ssr: true,
        fetchPolicy: 'network-only'
    });

    if (!threadNewPostData.loading && (!threadNewPostData.data || !threadNewPostData.data.threadNewPost)) {
        return <ErrorPage key={'pageContent'} style={{ width: '100%' }} errorType={'notfound'} />;
    }

    if (threadNewPostData && threadNewPostData.data && threadNewPostData.data.threadNewPost) {
        const threadIdParam = `t=${threadNewPostData.data.threadNewPost.thread_id}`;
        const postIdParam = `&p=${threadNewPostData.data.threadNewPost.post_id}`;
        const pageParam = `&page=${threadNewPostData.data.threadNewPost.page_number}`;
        const postAnchor = `#post${threadNewPostData.data.threadNewPost.post_id}`;

        const url = `/showthread.php?${threadIdParam}${postIdParam}${pageParam}&viewing_newpost=1${postAnchor}`;
        if (typeof window !== 'undefined') {
            return <Navigate to={url} replace={true} />;
        } else {
            return <RedirectWithStatus status={302} to={url} />;
        }
    } else {
        return <LoadingPageFull showSidebar={true} key={'pageContent'} />;
    }
};
