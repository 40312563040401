import ConditionalWrapper from '../../../helpers/ConditionalWrapper';
import useTranslation from '../../../hooks/useTranslation';
import { useAppTheme } from '@thestudentroom/lib.themes';

/* Styles */
import {
    DiscussionsWidgetTitle,
    DiscussionsListContainer,
    DiscussionLinkSkeletonContainer,
    DiscussionLinkSkeleton
} from './styles';

/* Components */
import DiscussionsWidgetTemplate from './DiscussionsWidgetTemplate';
import DiscussionCardSkeleton from './DiscussionCard/DiscussionCardSkeleton';

interface LoadingDiscussionsWidget {
    standalone: boolean;
    threadsPerPage: number;
    title: string;
    variant: 'card' | 'link';
}

export default function LoadingDiscussionsWidget(props: LoadingDiscussionsWidget) {
    const { t } = useTranslation();
    const theme = useAppTheme();

    const loadingSkeletons = [...Array(props.threadsPerPage)];

    return (
        <ConditionalWrapper
            condition={props.standalone}
            wrapper={(children: React.ReactNode) => (
                <DiscussionsWidgetTemplate
                    header={
                        <DiscussionsWidgetTitle>
                            {t(`widgets.${props.title}`)}
                        </DiscussionsWidgetTitle>
                    }
                >
                    {children}
                </DiscussionsWidgetTemplate>
            )}
        >
            <DiscussionsListContainer variant={props.variant} style={{marginBottom: theme.spacing(4)}}>
                {props.variant === 'card' ? (
                    loadingSkeletons.map((e, i) => (
                        <DiscussionCardSkeleton key={`skeleton_{${i}}`} />
                    ))
                ) : (
                    loadingSkeletons.map((e, i) => (
                        <DiscussionLinkSkeletonContainer numberOfItems={props.threadsPerPage}>
                            <DiscussionLinkSkeleton
                                key={`skeleton_{${i}}`}
                                variant="text"
                                width="100%"
                                data-testid='discussion-link-skeleton'
                            />
                        </DiscussionLinkSkeletonContainer>
                    ))
                )}
            </DiscussionsListContainer>
        </ConditionalWrapper>
    );
}
