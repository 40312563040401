import { createContext, ReactChild } from 'react';

export type HttpContextData = {
    statusCode: number;
    redirectLocation?: string;
};

export const HttpContext = createContext<HttpContextData | null>(null);

type ProviderProps = {
    children?: ReactChild;
    context: HttpContextData;
};

export default function HttpProvider({ children, context }: ProviderProps) {
    return <HttpContext.Provider value={context}>{children}</HttpContext.Provider>;
}
