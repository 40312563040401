import styled from '@emotion/styled';
import { List } from '@mui/material';
import { PrimaryButton } from '@thestudentroom/lib.themes';

export const JoinBannerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${(props) => props.theme.spacing(5)};
    gap: ${(props) => props.theme.spacing(3)};
    @media (max-width: 980px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const JoinBannerImageContainer = styled.div`
    max-width: 347.16px;
    max-height: 293px;
`;

export const JoinBannerButton = styled(PrimaryButton)`
    width: ${(props) => `calc(50% - ${props.theme.spacing(1.5)})`};

    @media (max-width: 980px) {
        width: 100%;
    }
`;

export const JoinBannerList = styled(List)`
    padding: ${(props) => props.theme.spacing(0, 0, 7, 10)};
    color: ${(props) => props.theme.lilac.contrastText};
`;

export const JoinBannerTextContainer = styled.div`
    padding: ${(props) => props.theme.spacing(0)};
    width: 800px;
    justify-content: center;
    margin-left: ${(props) => props.theme.spacing(6)};

    @media (max-width: 980px) {
        width: 100%;
        margin-left: ${(props) => props.theme.spacing(0)};
        padding-top: ${(props) => props.theme.spacing(5)};
    }
`;

export const JoinBannerTitle = styled.h2`
    ${(props) => props.theme.typography.subtitle1};
    color: ${(props) => props.theme.lilac.contrastText};
    font-size: 26px;
    text-align: left;
    margin-top: 0px;
    line-height: 24px;
`;

export const JoinBannerText = styled.div`
    color: ${(props) => props.theme.lilac.contrastText};
    font-size: 16px;
`;
