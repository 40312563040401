/* Styles */
import {
    DiscussionCardContainer,
    UserDetails,
    ThreadOverview,
    ThreadDetails,
    ThreadTimestamp,
    UserContainer,
    ThreadPostContainer
} from './styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

/* MUI Components */
import Skeleton from '@mui/material/Skeleton';

export default function DiscussionCardSkeleton() {
    const theme = useAppTheme();

    return (
        <DiscussionCardContainer>
            <UserContainer>
                <Skeleton
                    variant="circular"
                    sx={{
                        width: '40px',
                        height: '40px',
                        boxSizing: 'border-box',
                        marginRight: theme.spacing(3),
                        '@media (min-width: 671px)': {
                            width: '44px',
                            height: '44px'
                        }
                    }}
                />

                <UserDetails>
                    <Skeleton width={'100px'} variant="text" />
                    <Skeleton width={'75px'} variant="text" />
                </UserDetails>
            </UserContainer>

            <ThreadOverview>
                <Skeleton width={'75%'} variant="text" />
                <Skeleton width={'50%'} variant="text" />
            </ThreadOverview>

            <ThreadDetails>
                <ThreadTimestamp>
                    <Skeleton width={'100px'} variant="text" />
                </ThreadTimestamp>

                <ThreadPostContainer>
                    <Skeleton width={'50px'} variant="text" />
                </ThreadPostContainer>
            </ThreadDetails>
        </DiscussionCardContainer>
    );
}
