import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

export const AnnouncementBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background-color: ${(props) => props.theme.palette.tertiary.main};
    text-align: center;
    height: 66px;
    width: 100%;
    margin-bottom: ${(props) => props.theme.spacing(5)};

    @media (min-width: 671px) {
        height: 41px;
    }
`;

export const AnnouncementContainer = styled.div`
    margin: ${(props) => props.theme.spacing(2)};
    min-width: 0px;

    @media (min-width: 671px) {
        margin: 0px ${(props) => props.theme.spacing(6)};
    }
`;

export const StyledIconButton = styled(IconButton)`
    width: 35px;
    height: 35px;
    color: ${(props) => props.theme.text.secondary};
    margin: ${(props) => props.theme.spacing(1)};

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
`;
