import styled from '@emotion/styled';
import {
    LayoutContent
} from '../../Layouts/Main/styles';

const PromoBlockContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

export const PromoBlockInnerContainer = styled(LayoutContent)`
    padding-bottom: 0;
    background-color: transparent;
    padding: ${props => props.theme.spacing(4)};    

    @media (min-width: 1350px) {
        padding: ${props => props.theme.spacing(0, 0, 5, 0)};
    }

    @media (min-width: 671px) {
        padding: ${props => props.theme.spacing(4, 4, 5, 4)};
    }
`;

export const MainBannerContainer = styled(PromoBlockContainer)`
    background-color: ${props => props.theme.palette.tertiary.main};
`;

export const SeasonalContainer = styled(PromoBlockContainer)`
    background-color: ${props => props.theme.palette.info.main};
    margin-top: ${props => props.theme.spacing(-3)};

    @media (min-width: 981px) {
        margin-top: ${props => props.theme.spacing(0)};
    }
`;

export const JoinContainer = styled(PromoBlockContainer)`
    background-color: ${props => props.theme.background.paper};
    display: flex;
    justify-content: center;
    position: relative;

    @media (min-width: 981px) {
        background-color: ${props => props.theme.palette.tertiary.light};
    }
`;

export const AppReminderContainer = styled.div`
    background-color: ${props => props.theme.background.paper};
    @media (max-width: 981px) {
        padding: ${props => props.theme.spacing(2)};
        margin-bottom: ${props => props.theme.spacing(2)};
    }
`;

export const QuoteBannerContainer = styled.div`
    background-color: ${props => props.theme.palette.primary.main}50;

    @media (max-width: 980px) {
        margin-top: ${(props) => props.theme.spacing(-13)};
    }
`;
