/* APIs */
import { GET_WATCHED_TOPIC_THREADS } from '../../../../api/Thread/graph';
import { useQuery as useGraphQLQuery } from '@apollo/client';

/* Components */
import DiscussionWidgetTemplate from '../DiscussionWidgetTemplate';

interface WatchedThreadsProps {
    topicSelector: any;
}

export default function WatchedThreads({ topicSelector }: WatchedThreadsProps) {
    const { loading: watchedTopicThreadsLoading, data: watchedTopicThreadData } = useGraphQLQuery(GET_WATCHED_TOPIC_THREADS, {
        variables: {
            perPage: 5,
            page: 1,
            orderBy: 'lastpost',
            orderByDirection: 'desc',
        },
        errorPolicy: 'all',
        ssr: true,
        fetchPolicy: 'no-cache'
    });

    return (
        <DiscussionWidgetTemplate
            title={'watched-topics'}
            loading={watchedTopicThreadsLoading}
            discussionListData={watchedTopicThreadData}
            topicSelector={topicSelector}
        />
    );
}
