import { shuffle } from '../../../helpers/Arrays';
import { PartnershipBlock } from '../../../types/learning_provider';


export default function(partnership_blocks: PartnershipBlock[]): PartnershipBlock|null {
    if (partnership_blocks.length > 0) {
        // Shuffle the blocks
        const partnershipBlocks = shuffle([...partnership_blocks]);
        return partnershipBlocks[0];
    }

    return null;
}
