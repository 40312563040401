import useTranslation from '../../../hooks/useTranslation';
import {
    AppReminderContainer,
    AppReminderSvgContainer,
    AppReminderTitle,
    AppReminderText
} from './styles';
import { runtimeConfig } from '../../../runtimeConfig';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { PrimaryButton } from '@thestudentroom/lib.themes';
import redirectToAppStore from '../../../helpers/redirectToAppStore';

export default function AppReminder() {
    const { t } = useTranslation();

    return (
        <>
            <AppReminderContainer>
                <AppReminderSvgContainer>
                    <img
                        src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/mobile-app-in-hand.svg'}
                        style={{ display: 'block', margin: 'auto' }}
                        height={200}
                    />
                </AppReminderSvgContainer>
                <CardContent>
                    <AppReminderTitle> {t('home.Banners.AppReminder.heading')} </AppReminderTitle>
                    <AppReminderText> {t('home.Banners.AppReminder.mainText')} </AppReminderText>
                </CardContent>
                <CardActions>
                    <PrimaryButton
                        onClick={redirectToAppStore}
                        fullWidth
                    >
                        {t('home.Banners.AppReminder.buttonText')}
                    </PrimaryButton>
                </CardActions>
            </AppReminderContainer>
        </>
    );
}
