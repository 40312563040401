import { useState, useEffect } from 'react';
import Announcement from './Announcement';
import { AnnouncementBarContainer, AnnouncementContainer, StyledIconButton } from './styles';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { GET_ANNOUNCEMENTS } from '../../api/Thread/graph';
import CustomCarousel from '../CustomCarousel';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

interface AnnouncementsBarProps {
    forumIds: number[];
}

export default function AnnouncementsBar(props: AnnouncementsBarProps) {
    const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(1);
    const [transitionEnabled, setTransitionEnabled] = useState(true);
    const [autoPlayEnabled, setAutoPlayEnabled] = useState(false);

    // query the real announcement data from vbuletin
    const announcementsData = useGraphQLQuery(GET_ANNOUNCEMENTS, {
        variables: { forumIds: props.forumIds },
        errorPolicy: 'all',
        onCompleted: () => setAutoPlayEnabled(true),
        ssr: true
    });

    const announcements =
        !announcementsData.error && announcementsData.data && announcementsData.data.announcements
            ? [...announcementsData.data.announcements]
            : [];

    // start with a copy of the last item and end with a copy of the first item for infinite looping
    const announcementsLoop = [
        announcements[announcements.length - 1],
        ...announcements,
        announcements[0]
    ];

    useEffect(() => {
        // auto-play announcements while page is active
        window.addEventListener('focus', () => setAutoPlayEnabled(true));

        // turn off auto-play while page is inactive
        window.addEventListener('blur', () => setAutoPlayEnabled(false));
    }, []);

    const handleNext = () => {
        setTransitionEnabled(true);
        setCurrentAnnouncementIndex((prevIndex) =>
            prevIndex < announcementsLoop.length - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const handleBack = () => {
        setTransitionEnabled(true);
        setCurrentAnnouncementIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    // automatically go to the next announcement every 6.5s
    useEffect(() => {
        if (autoPlayEnabled) {
            const timeout = setTimeout(() => {
                handleNext();
            }, 6500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [currentAnnouncementIndex, autoPlayEnabled]);

    const handleTransitionEnd = () => {
        if (currentAnnouncementIndex === 0) {
            // slide 0 is a copy of the last slide so temporarily disable the transition
            // and set the current slide to the actual last slide to create an infinite loop
            setTransitionEnabled(false);
            setCurrentAnnouncementIndex(announcementsLoop.length - 2);
        } else if (currentAnnouncementIndex === announcementsLoop.length - 1) {
            // slide announcementsData.length-1 is a copy of the first slide so temporarily disable the transition
            // and set the current slide to the actual first slide to create an infinite loop
            setTransitionEnabled(false);
            setCurrentAnnouncementIndex(1);
        }
    };

    // if we have no announcements, don't render anything
    if (announcements.length === 0) return null;

    const getAnnouncementURL = (title: string) => {
        const titleLink = title.match(/href=('|")(.*?)('|")/gi);

        // return an empty string if link tags are not found
        if (titleLink == null || !title.includes('</a>')) {
            return '';
        }

        return titleLink[0]
            .replace(/('|")/gi, '')
            .replace(/href=/i, '')
            .replace('https://(.*?).thestudentroom.co.uk', '');
    };

    // reformat the content for each announcement content and map it to an announcement component
    const slides = announcementsLoop.map((announcement, index) => (
        <Announcement
            key={`announcement-${announcement.id}`}
            target={
                announcement.title.match(/target=('|")_blank('|")/gi) != null ? '_blank' : '_self'
            }
            title={announcement.title
                .replace(/<a href=('|")(.*?)('|")>/i, '')
                .replace('</a>', '')
                .trim()}
            url={getAnnouncementURL(announcement.title)}
            slideIndex={index}
            id={announcement.id}
        />
    ));

    return (
        <AnnouncementBarContainer data-cy="announcements-bar">
            <StyledIconButton size="small" onClick={handleBack} aria-label="left-arrow">
                <MdChevronLeft size={20} />
            </StyledIconButton>
            <AnnouncementContainer>
                <CustomCarousel
                    currentSlideIndex={currentAnnouncementIndex}
                    onNext={handleNext}
                    onBack={handleBack}
                    slides={slides}
                    onTransitionEnd={handleTransitionEnd}
                    disableTransition={!transitionEnabled}
                />
            </AnnouncementContainer>
            <StyledIconButton size="small" onClick={handleNext} aria-label="right-arrow">
                <MdChevronRight size={20} />
            </StyledIconButton>
        </AnnouncementBarContainer>
    );
}
