import * as React from 'react';
import {
    QuoteCarouselContainer,
    QuoteContainer,
    Quote,
    StyledStepper,
    StepperButton,
    QuoteTitle,
    QuoteText,
    QuoteSubText,
    QuoteMark,
    LeftCoffeeCup,
    RightCoffeeCup
} from './styles';

import { useAppTheme } from '@thestudentroom/lib.themes';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { runtimeConfig } from '../../runtimeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';

interface CarouselProps {
    title: string;
    items: any;
}

export default function ({items, title}: CarouselProps) {
    const [activeStep, setActiveStep] = React.useState(0);
    const isMobile = useMediaQuery('(max-width:770px)');
    const isTablet = useMediaQuery('(max-width:1300px)');

    const maxSteps  = isMobile ? items.length : items.length - (isTablet ? 1 : 2);
    const theme = useAppTheme();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getBackgroundColour = (index: any) => {
        const realIndex = (index+1) % 4;
        switch (realIndex) {
            case 1:
                return 'white';
            case 2:
                return `${theme.palette.primary.main}`;
            case 3:
                return `${theme.palette.info.main}`;
            case 0:
                return `${theme.palette.tertiary.main}`;
            default:
                'white';
        }
    };

    return (
        <QuoteCarouselContainer>
            <QuoteTitle data-testid="quote-title">
                {title}
            </QuoteTitle>
            <QuoteContainer>
                {items.slice(activeStep, items.length).map((quote: any, index: number) => (
                    <Quote
                        style={{
                            color:
                                index + activeStep == 1 || index + activeStep == 5
                                    ? `${theme.palette.common.white}`
                                    : `${theme.palette.common.black}`,
                            backgroundColor: getBackgroundColour(index + activeStep)
                        }}
                    >
                        <QuoteMark size={40} />
                        <QuoteText>
                            {quote.text}
                        </QuoteText>
                        <QuoteSubText>
                            {quote.subtext}
                        </QuoteSubText>
                    </Quote>
                ))}
            </QuoteContainer>

            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <LeftCoffeeCup
                        src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/navy-coffee-cup-facing-right.svg'}
                    />
                </div>
                <div>
                    <RightCoffeeCup
                        src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/purple-coffee-cup-facing-left.svg'}
                    />
                </div>
            </div>

            <StyledStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <StepperButton
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        aria-label="next"
                    >
                        <MdChevronRight
                            size={20}
                            data-testid="right-chevron"
                            fill={theme.blue.richBlue}
                        />
                    </StepperButton>
                }
                backButton={
                    <StepperButton
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        aria-label="back"
                    >
                        <MdChevronLeft
                            size={20}
                            data-testid="left-chevron"
                            fill={theme.blue.richBlue}
                        />
                    </StepperButton>
                }
            />
        </QuoteCarouselContainer>
    );
}
