import { setPageTargetingParams } from '../../../store/adverts/actions';

export default (reduxDispatch: Function) => {
    // Set advert targeting key-values
    const targetingKeyValues = {
        p_t: 'home',
        p_f_id: '0',
        p_t_id: '0',
        p_t_p: '0',
        p_u: 'home',
        page_version: 'home_react',

        // api.categorisation output
        page_topics: [],
        main_page_topics: []
    };
    reduxDispatch(setPageTargetingParams(targetingKeyValues));
};
