/**
 * Determines whether the app download prompt should be shown or not
 * @returns true if the prompt should be shown, false otherwise
 */
export default function appDownloadPromptCheck(): boolean {
    const appDownloadPromptDataKey = 'app-download-prompt-data'
    const appDownloadPromptData = localStorage.getItem(appDownloadPromptDataKey);
    const currentTime = new Date().getTime();

    if (appDownloadPromptData) {
        const data = JSON.parse(appDownloadPromptData);

        // don't show the prompt if it has been shown 3 times already
        if (data.numberOfTimesShown >= 3) return false;

        const timeDifferenceInDays = (currentTime - data.lastShown) / (1000 * 3600 * 24);

        // don't show the prompt if it has been shown in the last 1 day
        if (timeDifferenceInDays < 1) return false;

        data.numberOfTimesShown += 1;
        data.lastShown = currentTime;
        localStorage.setItem(appDownloadPromptDataKey, JSON.stringify(data));
    } else {
        localStorage.setItem(appDownloadPromptDataKey, JSON.stringify({ numberOfTimesShown: 1, lastShown: currentTime }));
    }

    return true;
}
