/* React */
import { useState } from 'react';

/* Components */
import WatchedThreads from './WatchedThreads';
import WatchedForums from './WatchedForums';

/* Styles */
import { TopicContainer, TopicList, Topic } from '../../../TopicSelector/styles';

export default function WatchedThreadWidget() {
    const [category, setCategory] = useState('Discussions');

    const categories: { [key: string]: string } = {
        Discussions: 'Discussions',
        Topics: 'Topics'
    };

    const topicSelector = (
        <TopicContainer>
            <TopicList>
                {Object.keys(categories).map((item: string) => {
                    return (
                        <Topic
                            selected={category === categories[item]}
                            label={item}
                            onClick={() => setCategory(categories[item])}
                        />
                    );
                })}
            </TopicList>
        </TopicContainer>
    );

    return category == 'Discussions' ? (
        <WatchedThreads topicSelector={topicSelector} />
    ) : (
        <WatchedForums topicSelector={topicSelector} />
    );
}
