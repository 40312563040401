import styled from '@emotion/styled';
import { MobileStepper, IconButton } from '@mui/material';
import { MdFormatQuote } from 'react-icons/md';

export const QuoteContainer = styled.div`
    height: 100%;
    padding-top: ${(props) => props.theme.spacing(0)};
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (min-width: 768px) {
        gap: 1%;
    }

    @media (min-width: 1300px) {
        gap: 2%;
    }
`;

export const QuoteSubText = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.8399999737739563px;
    text-align: left;
`;

export const QuoteTitle = styled.h2`
    margin: 0;
    ${(props) => props.theme.typography.subtitle2};
    font-size: 21px;
    letter-spacing: 0.8399999737739563px;
    text-align: left;

    @media (max-width: 981px) {
        text-size: 24px;
        line-height: 21px;
    }
`;

export const QuoteText = styled.h3`
    ${(props) => props.theme.text.headline6};

    margin: 0;
    font-size: 20px;
    font-weight: 570;
    line-height: 30px;
    letter-spacing: 0.8399999737739563px;
    text-align: left;

    @media (max-width: 981px) {
        font-size: 16px;
        font-weight: 570;
        line-height: 32px;
        letter-spacing: 0.8399999737739563px;
        text-align: left;
    }
    @media (max-width: 599px) {
        font-size: 14px;
    }
`;

export const QuoteCarouselContainer = styled.div`
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    gap: 20px;
`;

export const StepperButton = styled(IconButton)`
    padding: ${(props) => props.theme.spacing(3)};

    &:hover {
        background-color: ${(props) => props.theme.button.secondaryButtonHover};
    }

    &.Mui-disabled {
        visibility: hidden;
    }
`;

export const StyledStepper = styled(MobileStepper)`
    padding: 0px;
    background-color: unset;
    justify-content: center;
    .MuiMobileStepper-dot {
        margin: 0px ${(props) => props.theme.spacing(2)};
        height: 7px;
        width: 7px;
        background-color: ${(props) => props.theme.palette.background.paper};
    }

    .MuiMobileStepper-dotActive {
        background-color: ${(props) => props.theme.blue.richBlue};
    }
`;

export const Quote = styled.div`
    width: 100%;
    min-width: 100%;
    height: 340px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    border-radius: 40px 40px 0px 40px;

    @media (max-width: 500px) {
        padding-left: ${(props) => props.theme.spacing(15)};
    }

    @media (min-width: 768px) {
        width: 49%;
        min-width: 49%;
    }

    @media (min-width: 1300px) {
        width: 32%;
        min-width: 32%;
    }
`;

export const StyledIconButton = styled(IconButton)`
    width: 35px;
    height: 35px;
    color: ${(props) => props.theme.text.secondary};
    margin: ${(props) => props.theme.spacing(1)};

    &:hover {
        background-color: ${(props) => props.theme.white}50;
    }
`;

export const QuoteMark = styled(MdFormatQuote)`
    transform: rotate(180deg);
    position: absolute;
    top: 10px;
    left: 10px;
`;


export const RightCoffeeCup = styled.img`
    width: 66px;
    height: 97px;

    @media (max-width: 500px) {
        display: none;
    }
`;

export const LeftCoffeeCup = styled.img`
    width: 66px;
    height: 97px;
`;
