import styled from '@emotion/styled';

export const ThreadListWidget = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(3)};
    margin: ${(props) => props.theme.spacing(4, 0)};
`;

export const ThreadListTitle = styled.p`
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    text-transform: capitalize;
    padding: 8px 14px;
`;

export const ThreadListTitleContainer = styled.div`
    border-radius: 8px;
    background: ${(props) => props.theme.palette.primary.main};
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: ${(props) => props.theme.spacing(2)};
`;

export const WidgetButtonContainer = styled.div`
    margin: ${(props) => props.theme.spacing(0)};
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
