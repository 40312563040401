import styled from '@emotion/styled';
import Link from '../Link';

export const PostOuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(2, 2, 5, 2)};
    position: relative;
    background-color: ${(props) => props.theme.white};
    border-radius: ${(props) => props.theme.spacing(2)};
    border: 1px solid ${(props) => props.theme.grey.main};
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(4, 4, 5, 4)};
    }
`;

export const BlurContainer = styled.div<{ isLockdown: boolean }>`
    position: relative;
    background-color: white;
    opacity: ${(props) => (props.isLockdown ? '0.6' : 'unset')};
    filter: ${(props) => (props.isLockdown ? 'blur(4px)' : 'unset')};
    pointer-events: ${(props) => (props.isLockdown ? 'none' : 'auto')};
    user-select: ${(props) => (props.isLockdown ? 'none' : 'auto')};
`;

export const PostHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
`;

export const PostContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-all;
    margin: ${(props) => props.theme.spacing(0, 3)};

    & img {
        max-width: 100%;
    }

    @media (min-width: 671px) {
        margin: ${(props) => props.theme.spacing(0, 4)};
    }
`;

export const PostContent = styled.div<{ noto: boolean }>`
    padding-top: ${(props) => props.theme.spacing(5)};
    width: 100%;
    word-break: break-word;
    ${(props) => props.theme.typography.body2};
    font-family: ${(props) => props.theme.typography.fontFamily}, ${(props) => props.noto && 'Noto Color Emoji'};
    margin-bottom: ${(props) => props.theme.spacing(0)};

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.body1};
        font-family: ${(props) => props.theme.typography.fontFamily}, ${(props) => props.noto && 'Noto Color Emoji'};
    }
`;

export const CreatedAtText = styled.span`
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.caption};
    text-align: right;
    text-transform: lowercase;
`;

export const EditedAt = styled.div`
    font-size: 12px;
    margin-top: ${(props) => props.theme.spacing(4)};
`;

export const EditedAtText = styled.span`
    color: ${(props) => props.theme.text.tertiary};
    ${(props) => props.theme.typography.caption};
    text-align: right;

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.body2};
    }
`;

export const PostDetailsContainer = styled.div<{ isThreadOpen: boolean }>`
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => props.isThreadOpen ? props.theme.lilac.light : props.theme.grey.light};
    border-radius: 4px;
    padding: ${(props) => props.theme.spacing(2, 3)};

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(2, 4)};
    }
`;

export const PostNumberContainer = styled(Link)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.caption};
    text-align: right;
`;
