import styled from '@emotion/styled';

export const DataPromptContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 109px;
    position: relative;
    background-color: ${(props) => props.theme.lilac.light};
    border-radius: ${(props) => props.theme.spacing(3)};
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-between;
    padding: ${(props) => props.theme.spacing(0)};
    overflow: hidden;
    box-shadow: 0px 2px 1px 0px ${(props) => props.theme.palette.primary.main}60;
`;

export const DataPromptTitle = styled.h3`
    max-width: 589.55px;
    margin-top: ${(props) => props.theme.spacing(0)};
    margin-bottom: ${(props) => props.theme.spacing(0)};
    padding: ${(props) => props.theme.spacing(2)};
    padding-left: 0px;

    @media (max-width: 645px) {
        font-size: 14px;
    }

    @media (max-width: 499px) {
        padding-top: ${(props) => props.theme.spacing(0)};
        line-height: normal;
        padding-bottom: ${(props) => props.theme.spacing(1)};
    }
`;

export const SVGContainer = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${(props) => props.theme.palette.primary.main};
    max-width: 159.16px;
    width: 100%;
    height: 109px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DataPromptText = styled.a`
    color: ${(props) => props.theme.palette.primary.main};
    text-transform: none;
    text-decoration: underline;
    cursor: pointer;
`;

export const DataPromptMainText = styled.p`
    margin: ${(props) => props.theme.spacing(0)};
    text-align: left;

    @media (max-width: 1025px) {
        line-height: 20px;
    }
    @media (max-width: 980px) {
        line-height: 19px;
        letter-spacing: normal;
    }
    @media (max-width: 645px) {
        font-size: 12px;
    }
`;

export const Desktop = styled.div`
    display: block;

    @media (max-width: 670px) {
        display: none;
    }
`;

export const Mobile = styled.div`
    display: block;

    @media (min-width: 671px) {
        display: none;
    }
`;

export const TextContainer = styled.div`
    max-width: 632.75px;
    padding: ${(props) => props.theme.spacing(0)};
    padding-left: 5%;
    margin: ${(props) => props.theme.spacing(0)};

    @media (max-width: 599px) {
        padding-left: 2%;
    }
`;

export const DataPromptIcon = styled.img`
    display: block;
    margin: auto;
    top: 13%;
    left: 6%;
    width: 100px;
`;
