import { useEffect } from "react";
import { PrimaryButton } from "@thestudentroom/lib.themes";
import { recordVisible, recordClick } from "@thestudentroom/datalayer";
import Drawer from '@mui/material/Drawer';
import {
    Content,
    AppButtonContainer,
    AppButtonIcon,
    PromptText,
    ContinueButton
} from './styles';

interface appDownloadPromptProps {
    triggeredAction?: string;
    showPrompt: boolean;
    setShowPrompt: Function;
}

export default function AppDownloadPrompt({ triggeredAction, showPrompt, setShowPrompt } : appDownloadPromptProps) {    
    const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';
    const currentOS = /iPad|iPhone|iPod/.test(userAgent) ? 'ios' : (/android/i.test(userAgent) ? 'android' : undefined);
    const isMobile = currentOS !== undefined;
    const open = showPrompt && isMobile;
    const appStoreUrls = {
        ios: 'https://apps.apple.com/app/apple-store/id6459021417?pt=1101943&ct=replyprompt&mt=8',
        android: 'https://play.google.com/store/apps/details?id=uk.co.thestudentroom.app&referrer=utm_source%3Dtsr%26utm_medium%3Dprompt%26utm_campaign%3Dreply%2520prompt%26anid%3Dadmob'
    };

    const handleOpenAppStore = () => {
        if (!isMobile) {
            setShowPrompt(false);
            return;
        }

        const url = appStoreUrls[currentOS];

        recordClick({
            group: 'app-download-prompt',
            section: triggeredAction,
            subsection: currentOS,
            url: url
        });

        window.open(url, '_blank');
        setShowPrompt(false);
    };

    useEffect(() => {
        if (open) {
            recordVisible({
                group: 'app-download-prompt',
                section: triggeredAction,
                subsection: currentOS,
                url: appStoreUrls[currentOS]
            });

            // close after 8 seconds
            setTimeout(() => {
                setShowPrompt(false);
            }, 8000);
        }
    }, [open]);

    return (
        <Drawer
            data-testid="app-download-prompt"
            anchor={'bottom'}
            open={open}
            onClose={() => setShowPrompt(false)}
            hideBackdrop={true}
            variant="persistent"
            ModalProps={{
                disableScrollLock: true
            }}
        >
            <Content>                
                <AppButtonContainer onClick={handleOpenAppStore}>                    
                    <AppButtonIcon
                        src="https://public.thestudentroom.co.uk/theme/tsr/images/logos/colour-logo-only.svg" alt="TSR-logo"
                    />                    
                    <PromptText>Try out the app</PromptText>                    
                    <PrimaryButton>
                        Open
                    </PrimaryButton>
                </AppButtonContainer>  
                <ContinueButton
                    onClick={() => setShowPrompt(false)}
                >
                    Continue on web
                </ContinueButton>
            </Content>
        </Drawer>
    );
}
