import styled from '@emotion/styled';

export const CarouselContainer = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

export const CarouselContent = styled.div<{ index: number }>`
    display: flex;
    transition: all 300ms linear;
    transform: translateX(${(props) => -props.index * 100}%);
`;

export const CarouselItem = styled.div<{ visible: boolean }>`
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    height: ${(props) => (props.visible ? 'auto' : 0)};
`;
