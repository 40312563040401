import styled from '@emotion/styled';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../client';

const AdContainer = styled.div`
    display: none;

    @media (min-width: 981px) {
        display: flex;
        justify-content: center;
    }
`;

const SidebarHPU = loadable(() => import('./index'));

// Sidebar HPU/MPU advert
// Only visible on desktop
export default function SidebarHPUWrapper() {
    const advertState = useSelector((state: RootState) => state.adverts);

    if (advertState.ownership_learning_provider) {
        return null;
    }

    return (
        <AdContainer>
            <SidebarHPU />
        </AdContainer>
    );
}
