import { DiscussionCardContainer } from './styles';
import Skeleton from '@mui/material/Skeleton';

export default function DiscussionCardSkeleton() {
    return (
        <DiscussionCardContainer data-testid='discussion-card-skeleton'>
            <Skeleton width={'50%'} variant="text" />
            <Skeleton width={'100%'} variant="text" />            
            <Skeleton width={'30%'} variant="text" />
        </DiscussionCardContainer>
    );
}
