/* Libraries */
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';

/* API */
import { GET_PAGE_NUMBER } from '../../api/Thread/graph';

/* Components */
import { LoadingPageFull } from '../../components/Loading';
import RedirectWithStatus from '../../helpers/RedirectWithStatus';
import ErrorPage from '../Error';

interface GoToPostProps {
    postId: number;
    threadId: number;
}

// Create our component
export default (props: GoToPostProps) => {

    const pageNumberData = useGraphQLQuery(GET_PAGE_NUMBER, {
        variables: {
            postId: props.postId,
            perPage: 20,
            threadId: props.threadId
        },
        errorPolicy: 'all',
        ssr: true
    });

    if (!pageNumberData.loading && (!pageNumberData.data || !pageNumberData.data.page)) {
        return <ErrorPage key={'pageContent'} style={{ width: '100%' }} errorType={'notfound'} />;
    }

    if (pageNumberData && pageNumberData.data && pageNumberData.data.page && pageNumberData.data.page.page_number) {
        const threadIdParam = `t=${props.threadId || pageNumberData.data.page.thread_id}`;
        const postIdParam = `&p=${props.postId}`;
        const pageParam = `&page=${pageNumberData.data.page.page_number}`;
        const postAnchor = `#post${props.postId}`;

        const url = `/showthread.php?${threadIdParam}${postIdParam}${pageParam}${postAnchor}`;
        if (typeof window !== 'undefined') {
            return <Navigate to={url} replace={true} />;
        } else {
            return <RedirectWithStatus status={302} to={url} />;
        }
    } else {
        return <LoadingPageFull showSidebar={true} key={'pageContent'} />;
    }
};
