import { useState, MouseEventHandler, TransitionEventHandler } from 'react';
import { CarouselContainer, CarouselContent, CarouselItem } from './styles';

interface CustomCarouselProps {
    currentSlideIndex: number;
    onNext: MouseEventHandler;
    onBack: MouseEventHandler;
    slides: JSX.Element[];
    onTransitionEnd?: TransitionEventHandler;
    disableTransition?: boolean;
}

export default function CustomCarousel(props: CustomCarouselProps) {
    const [touchStartPosition, setTouchStartPosition] = useState(null);
    const [touchEndPosition, setTouchEndPosition] = useState(null);

    const handleTouchStart = (e: any) => {
        const touchPosition = e.touches[0].clientX;
        setTouchStartPosition(touchPosition);
    };

    const handleTouchMove = (e: any) => {
        setTouchEndPosition(e.touches[0].clientX);
    };

    const handleTouchEnd = (e: any) => {
        if (touchStartPosition === null || touchEndPosition === null) return;

        const difference = touchStartPosition - touchEndPosition;

        if (difference > 50) {
            props.onNext(e);
        }
        if (difference < -50) {
            props.onBack(e);
        }

        setTouchStartPosition(null);
        setTouchEndPosition(null);
    };

    return (
        <CarouselContainer
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            data-testid="carousel-container"
        >
            <CarouselContent
                index={props.currentSlideIndex}
                onTransitionEnd={props.onTransitionEnd}
                style={{ transition: props.disableTransition ? 'none' : undefined }}
                data-testid="carousel-content"
            >
                {props.slides.map((slide, index) => (
                    <CarouselItem visible={props.currentSlideIndex === index}>{slide}</CarouselItem>
                ))}
            </CarouselContent>
        </CarouselContainer>
    );
}
