/* Styles */
import {
    DataPromptContainer,
    SVGContainer,
    TextContainer,
    DataPromptTitle,
    DataPromptText,
    DataPromptMainText,
    DataPromptIcon,
    Desktop,
    Mobile
} from './styles';

import useTranslation from '../../../hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/modals/actions';
import { runtimeConfig } from '../../../runtimeConfig';

export default function DataPrompt() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function promptPressed() {
        dispatch(showModal(true, 'homefeeddpc', { route: 'homefeeddpc' }, true));
    }

    return (
        <DataPromptContainer onClick={promptPressed}>
            <SVGContainer>
                <DataPromptIcon src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/user-personalise.svg'} />
            </SVGContainer>
            <TextContainer>
                <DataPromptTitle>{t('home.Banners.dataPrompt.heading')}</DataPromptTitle>
                <Desktop>
                    <DataPromptMainText>
                        {t('home.Banners.dataPrompt.mainText')}
                        &nbsp;
                        <DataPromptText onClick={promptPressed}>
                            {t('home.Banners.dataPrompt.clickableText')}
                        </DataPromptText>
                    </DataPromptMainText>
                </Desktop>
                <Mobile>
                    <DataPromptMainText>
                        {t('home.Banners.dataPrompt.mobilemainText')}
                        &nbsp;
                        <DataPromptText onClick={promptPressed}>
                            {t('home.Banners.dataPrompt.clickableText')}
                        </DataPromptText>
                    </DataPromptMainText>
                </Mobile>
            </TextContainer>
        </DataPromptContainer>
    );
}
