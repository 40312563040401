import useTranslation from '../../../hooks/useTranslation';
import {
    MemberBannerCard,
    MemberCardContent,
    MemberCardImageContainer,
    MemberCardTitle,
    MemberCardText,
} from './styles';
import { runtimeConfig } from '../../../runtimeConfig';
import { PrimaryButton } from '@thestudentroom/lib.themes';

export default function MemberBanner() {
    const { t } = useTranslation();

    return (
        <MemberBannerCard>
            <MemberCardImageContainer>
                <img
                    src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/navy-coffee-cup-facing-right.svg'}
                    height={160}
                    width={108}
                    style={{ marginTop: -20 }}
                />
                <img
                    src={runtimeConfig.publicDomain + '/theme/tsr/images/graphics/pink-coffee-cup-facing-left.svg'}
                    height={160}
                    width={108}
                    style={{ marginTop: 10 }}
                />
            </MemberCardImageContainer>
            <MemberCardContent>
                <MemberCardTitle>
                    {t('home.Banners.memberCards.unanswered.heading')}
                </MemberCardTitle>
                <MemberCardText>{t('home.Banners.memberCards.unanswered.mainText')}</MemberCardText>
                <PrimaryButton href="/unanswered">
                    {t('home.Banners.memberCards.unanswered.buttonText')}
                </PrimaryButton>
            </MemberCardContent>
        </MemberBannerCard>
    );
}
