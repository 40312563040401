import { setPageTargetingParams } from '../../../store/adverts/actions';
import { Thread } from '../../../types/thread';

export default (reduxDispatch: Function, thread: Thread, pageNumber: number) => {

    function getClosedDate(thread: Thread) {
        if (thread.closed_date) {
            const date = new Date(thread.closed_date * 1000);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear().toString().slice(-2);

            return `${month}/${year}`;
        }

        return 'BLANK';
    }

    if (thread && thread.status_code == 200) {
        const currentForum = thread.forums[thread.forums.length - 1];
        const forumId = currentForum.id;

        // Set advert targeting key-values
        const threadTargetingKeyValues = {
            p_t: 'showthread',
            p_f_id: forumId.toString(),
            p_t_id: thread.id.toString(),
            p_t_p: pageNumber.toString(),
            p_u: 't' + thread.id.toString(),
            page_version: 'thread_react',

            // api.categorisation output
            page_topics: thread.page_topic_codes ?? [],
            main_page_topics: thread.main_page_topic_codes ?? [],

            // thread status
            thread_status: `${thread.open_status}`,
            thread_close_date: getClosedDate(thread)
        };

        reduxDispatch(setPageTargetingParams(threadTargetingKeyValues));
    }
};
