import styled from '@emotion/styled';
import { DiscussionBubble } from '@thestudentroom/lib.themes';

export const BannerTitle = styled.h1`
    color: ${props => props.theme.palette.secondary.contrastText};
    font-weight: 570;
    text-align: left;
    margin: ${(props) => props.theme.spacing(1, 0)};
    ${(props) => props.theme.text.headline3};

    @media (max-width: 1140px) {
        line-height: 50px;
        font-size: 38px;
    }

    @media (max-width: 768px) {
        line-height: 40px;
        margin-bottom: 0px;
        font-size: 28px;
    }

    @media (max-width: 500px) {
        line-height: 28px;
        font-size: 20px;
    }

    @media (min-width: 1025px) {
        white-space: nowrap;
    }
`;

export const BannerText = styled.span<{subText?: boolean}>`
    color: ${props => props.theme.palette.secondary.contrastText};
    text-align: left;
    ${(props) => props.theme.typography.subtitle1};
    max-height: 100%;
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: 0.84px;

    @media (max-width: 768px) {
        ${(props) => props.subText ? 'display: none;' : ''}
        font-size: 18px;
        line-height: 28px;
    }

    @media (max-width: 350px) {
        line-height: 22px;
        font-size: 16px;
    }
`;

export const BannerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0;

    @media (min-width: 671px) {
        height: 249px;
    }

    @media (min-width: 769px) {
        height: 366px;
    }

    @media (min-width: 910px) {
        height: 326px;
    }

    @media (min-width: 1025px) {
        flex-direction: row;
        align-items: flex-start;
        height: 236px;
    }
`;

export const BannerTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 14px;
    flex-grow: 1;
`;

export const BannerImageContainer = styled.div`
    z-index: 10;
    position: relative;
`;

export const StudentWithPhone = styled.img`
    width: 190px;
    height: 138px;
    z-index: 10;
    position: relative;

    @media (min-width: 560px) {
        width: 213px;
        height: 157px;
    }

    @media (min-width: 1025px) {
        width: 344px;
        height: 257px;
    }
`;

export const LeftBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    top: 12px;
    left: 0;
    padding: 0 !important;
    height: 330px;
    width: 180px;
    
    @media (min-width: 1700px) {
        display: block;
    }

    @media (max-width: 1024px) {
        display: block;
        height: 50px !important;
        width: 80px !important;
        top: 70%;
        left: 38%;
        border-radius: 20px !important;
        border-bottom-left-radius: 0px !important;
    }

    @media (max-width: 768px) {
        left: 24%;
    }
`;

export const TopRightBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: 1700px) {
        display: block;
    }

`;

export const BottomRightBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;

    @media (min-width: 1400px) {
        display: block;
    }
`;

export const MiddleBubble = styled(DiscussionBubble)`
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 9;

    @media (max-width: 1024px) {
        height: 100px !important;
        width: 150px !important;
        opacity: 0.5;
    }
`;
